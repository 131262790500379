import Checkbox from '@/components/shared/checkbox';
import { Policy } from '../../models/policy';
import { useFormContext } from '../form/FormContext';
import styles from './FormListSection.module.css';

interface Props {
  header: string;
  policiesList: Policy[];
  formKey: string;
}

const FormListSection: React.FC<Props> = ({
  header,
  policiesList,
  formKey,
}) => {
  const { togglePolicy, selectedPolicyKeys } = useFormContext();

  return (
    <div className={styles.form}>
      <header>{header}</header>
      <div className={styles['policy-list']}>
        {policiesList.map((policy) => {
          const isSelected = selectedPolicyKeys.some(
            ({ formKey: innerFormKey, policyKey }) =>
              innerFormKey === formKey && policyKey === policy.amsPolicyId
          );
          return (
            <Checkbox
              checked={isSelected}
              onChange={() => togglePolicy(policy.amsPolicyId, formKey)}
              label={`${policy.carrier.name} (${policy.policyNumber})`}
              theme={'crm'}
            />
          );
        })}
      </div>
    </div>
  );
};

export default FormListSection;
