import {
  booleanDropdownOptions,
  educationOptions,
  employmentStatusOptions,
  genderOptions,
  maritalStatusOptions,
  stateCodeOptions,
} from '../helpers';
import type { FormPage } from '../models/formTypes';

export const applicant: FormPage = {
  id: 'applicant',
  display: 'Applicant',
  icon: 'person',
  elements: [
    {
      id: 'contactDetails',
      display: 'Contact Details',
      type: 'section',
      elements: [
        {
          name: 'crm.accountId',
          display: 'accountId',
          type: 'hidden',
        },
        {
          name: 'applicant.firstName',
          display: 'First name',
          type: 'text',
          placeholder: 'Enter first name...',
          validation: {
            required: {
              value: true,
              message: 'First name is required',
            },
            maxLength: {
              value: 255,
              message: 'First name cannot be more than 255 characters',
            },
          },
        },
        {
          name: 'applicant.lastName',
          display: 'Last name',
          type: 'text',
          placeholder: 'Enter last name...',
          validation: {
            required: {
              value: true,
              message: 'Last name is required',
            },
            maxLength: {
              value: 255,
              message: 'Last name cannot be more than 255 characters',
            },
          },
        },
        {
          name: 'applicant.email',
          display: 'Email address',
          type: 'email',
          placeholder: 'example@website.com',
          validation: {
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
              message: 'Please enter a valid email',
            },
          },
        },
        {
          name: 'applicant.mainPhone',
          display: 'Phone Number',
          type: 'telephone',
        },
        // leaving it as readonly for now
        {
          name: 'insuranceProducts',
          display: 'Interests',
          type: 'readonly',
        },
        {
          name: 'isMultiPolicy',
          display: 'Multi-policy?',
          type: 'bool',
          options: booleanDropdownOptions,
        },
        {
          name: 'hasClaimHistory',
          display: 'Has claim history?',
          type: 'bool',
          options: booleanDropdownOptions,
        },
      ],
    },
    {
      id: 'additionalInfo',
      display: 'Additional Information',
      type: 'section',
      elements: [
        {
          name: 'applicant.dateOfBirth',
          display: 'Date of Birth',
          type: 'date',
        },
        {
          name: 'applicant.gender',
          display: 'Gender',
          placeholder: 'Select...',
          type: 'dropdown',
          options: genderOptions,
        },
        {
          name: 'applicant.maritalStatus',
          display: 'Marital status',
          placeholder: 'Select...',
          type: 'dropdown',
          options: maritalStatusOptions,
        },
        {
          name: 'applicant.education',
          display: 'Education',
          placeholder: 'Select...',
          type: 'dropdown',
          options: educationOptions,
        },
        {
          name: 'applicant.employmentStatus',
          display: 'Employment Status',
          placeholder: 'Select...',
          type: 'dropdown',
          options: employmentStatusOptions,
        },
        {
          name: 'applicant.industry',
          display: 'Industry',
          visibleIf: {
            fieldName: 'applicant.employmentStatus',
            includeList: ['Employed'],
          },
          placeholder: 'Select...',
          type: 'apidropdown',
          optionsBy: 'dropdown/industry',
        },
        {
          name: 'applicant.occupation',
          display: 'Occupation',
          visibleIf: {
            fieldName: 'applicant.employmentStatus',
            includeList: ['Employed'],
          },
          placeholder: 'Select...',
          type: 'apidropdown',
          optionsBy: 'dropdown/occ_{industry}',
        },
      ],
    },
    {
      id: 'currentAddress',
      display: 'Current Address',
      type: 'section',
      elements: [
        {
          name: 'applicant.currentAddress.lineOne',
          display: 'Street Address',
          type: 'addressautocomplete',
          placeholder: 'Enter street address...',
          actions: [
            {
              id: 'currentAddressZillow',
              type: 'zillow',
              fieldName: 'applicant.currentAddress',
            },
            {
              id: 'currentAddressGoogleMaps',
              type: 'google-maps',
              fieldName: 'applicant.currentAddress',
            },
          ],
          validation: {
            maxLength: {
              value: 255,
              message: 'Street address cannot be more than 255 characters',
            },
          },
        },
        {
          name: 'applicant.currentAddress.lineTwo',
          display: 'Address line 2',
          type: 'text',
          placeholder: 'Enter address line 2...',
          validation: {
            maxLength: {
              value: 50,
              message: 'Address line 2 cannot be more than 50 characters',
            },
          },
        },
        {
          name: 'applicant.currentAddress.city',
          display: 'City',
          type: 'text',
          placeholder: 'Enter city...',
        },
        {
          name: 'applicant.currentAddress.stateCode',
          display: 'State',
          placeholder: 'Select...',
          type: 'dropdown',
          options: stateCodeOptions,
        },
        {
          name: 'applicant.currentAddress.postalCode',
          display: 'Zip code',
          type: 'text',
          placeholder: 'Enter ZIP code...',
          validation: {
            pattern: {
              value: /^\d{5}(-\d{4})?$/,
              message: 'Please enter a valid ZIP code',
            },
          },
        },
        {
          name: 'applicant.hasMovedInLast3Years',
          display: 'Has moved in last 3 years?',
          type: 'bool',
          options: booleanDropdownOptions,
        },
      ],
    },
    {
      id: 'previousAddress',
      display: 'Previous Address',
      type: 'section',
      visibleIf: {
        fieldName: 'applicant.hasMovedInLast3Years',
        includeList: ['true', true],
      },
      elements: [
        {
          name: 'applicant.previousAddress.lineOne',
          display: 'Street Address',
          type: 'addressautocomplete',
          placeholder: 'Enter street address...',
          actions: [
            {
              id: 'previousAddressZillow',
              type: 'zillow',
              fieldName: 'applicant.previousAddress',
            },
            {
              id: 'previousAddressGoogleMaps',
              type: 'google-maps',
              fieldName: 'applicant.previousAddress',
            },
          ],
          validation: {
            maxLength: {
              value: 255,
              message: 'Street address cannot be more than 255 characters',
            },
          },
        },
        {
          name: 'applicant.previousAddress.lineTwo',
          display: 'Address line 2',
          type: 'text',
          placeholder: 'Enter address line 2...',
          validation: {
            maxLength: {
              value: 50,
              message: 'Address line 2 cannot be more than 50 characters',
            },
          },
        },
        {
          name: 'applicant.previousAddress.city',
          display: 'City',
          type: 'text',
          placeholder: 'Enter city...',
        },
        {
          name: 'applicant.previousAddress.stateCode',
          display: 'State',
          placeholder: 'Select...',
          type: 'dropdown',
          options: stateCodeOptions,
        },
        {
          name: 'applicant.previousAddress.postalCode',
          display: 'Zip code',
          type: 'text',
          placeholder: 'Enter ZIP code...',
          validation: {
            pattern: {
              value: /^\d{5}(-\d{4})?$/,
              message: 'Please enter a valid ZIP code',
            },
          },
        },
      ],
    },
    // {
    //   id: 'applicant',
    //   display: 'Applicant',
    //   type: 'section',
    //   elements: [
    //     {
    //       name: 'applicant.applicantType',
    //       display: 'Applicant Type',
    //       placeholder: 'Select...',
    //       type: 'dropdown',
    //       options: applicantTypeOptions,
    //     },
    //     {
    //       name: 'applicant.relation',
    //       display: 'Relation',
    //       placeholder: 'Select...',
    //       type: 'dropdown',
    //       options: relationOptions,
    //     },
    //   ],
    // },
  ],
};
