import { Customer } from '../models/customer';
import { RequestError, request } from '../../../components/common/utils';
import { EmailResponse } from '../models/emailResponse';
import { Form } from '../models/form';
import { Policy } from '../models/policy';
import { PolicyKeySet } from '../components/form/types';
import { EmailFormRequest } from '../models/emailRequest';
import { Contact } from '../models/contact';

export const getAccountDetails = async (
  accountId: string,
  authedFetch: typeof fetch
): Promise<Customer> => {
  const url = `/apim/customer/v1/customers/${accountId}`;

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const response = await request(url, requestOptions, authedFetch);
  if (response instanceof RequestError) {
    return Promise.reject();
  }
  return await response.json();
};

export const getAssociatedContactList = async (
  accountId: string,
  authedFetch: typeof fetch
): Promise<Contact[]> => {
  const url = `/apim/customer/v1/customers/${accountId}/associated-contacts`;
  const requestOptions: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const response = await request(url, requestOptions, authedFetch);
  if (response instanceof RequestError) {
    return Promise.reject();
  }
  return await response.json();
};

export const getPolicyList = async (
  accountId: string,
  authedFetch: typeof fetch
): Promise<Policy[]> => {
  const url = `/apim/policy/v1/customers/${accountId}/policies?source=dynamics`;

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const response = await request(url, requestOptions, authedFetch);
  if (response instanceof RequestError) {
    return Promise.reject();
  }
  return await response.json();
};

export const getFormList = async (
  authedFetch: typeof fetch
): Promise<Form[]> => {
  const url = `/apim/forms/v1/forms/metadata?metadata={"showInFusion":true}`;

  const requestOptions: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const response = await request(url, requestOptions, authedFetch);
  if (response instanceof RequestError) {
    return Promise.reject();
  }
  return await response.json();
};

export const sendEmail = async (
  name: string,
  customerId: string,
  emailAddress: string,
  emailFormRequests: EmailFormRequest[],
  dynamicsAccountId: string,
  username: string | undefined,
  authedFetch: typeof fetch
): Promise<EmailResponse[]> => {
  const url = `/apim/forms/v1/email/`;

  // const mergedList = ...

  const jsonBody = JSON.stringify({
    name,
    customerId,
    emailAddresses: [emailAddress],
    forms: emailFormRequests,
    dynamicsAccountId,
    username,
  });
  console.log(jsonBody);
  const requestOptions: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: jsonBody,
  };

  const response = await request(url, requestOptions, authedFetch);
  if (response instanceof RequestError) {
    return Promise.reject();
  }
  return await response.json();
};
