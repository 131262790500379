import type { FC } from 'react';
import { useEffect } from 'react';
import type { FormField, FormSection } from '@/services/forms/models/formTypes';
import { useFormContext } from 'react-hook-form';
import SubheadingDivider from '../../SubheadingDivider';
import ActionMapper from '../Actions';
import FieldMapper from '../FieldMapper';
import { useVisible } from '@/components/utility';

type Field = {
  display: string;
  page: string;
};

interface FormSectionProps {
  section: FormSection;
  searchResults?: Field[] | null;
}

const FormSection: FC<FormSectionProps> = ({ section, searchResults }) => {
  const { setValue } = useFormContext();
  const isVisible = useVisible(section.visibleIf);

  useEffect(() => {
    if (!isVisible) {
      section.elements.forEach((field) => {
        setValue(field.name, undefined, {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        });
      });
    }
  }, [isVisible]);

  if (!isVisible) return;

  return (
    <SubheadingDivider headingText={section.display}>
      {section.elements.map((field: FormField) => {
        field.highlight =
          searchResults?.some(
            (result: Field) => field.display === result.display
          ) || null;

        return (
          <div
            key={field.id || field.name}
            className={field.type === 'hidden' ? 'hidden' : ''}
          >
            <FieldMapper field={field} searchResults={searchResults} />
            {field.actions && (
              <div
                data-testid="action-container"
                className="flex flex-row gap-2 items-center"
              >
                {field.actions.map((action) => (
                  <ActionMapper key={action.id} action={action} />
                ))}
              </div>
            )}
          </div>
        );
      })}
    </SubheadingDivider>
  );
};

export { FormSection };
