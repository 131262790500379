import { useMemo, useRef, useState } from 'react';
import { Customer } from '../../models/customer';
import Button from '../../../../components/shared/button';
import Search from './search';
import AutoPull from '../dialogs/autoPull';
import { Policy } from '../../models/policy';
import CarrierButton from '../../../../components/shared/carrierButton';
import Tooltip from '../../../../components/shared/tooltip';
import { maxUploadSize } from '../../../../components/common/config';
import styles from './controlBar.module.css';
import '../../../../theme.module.css';

export default function ControlBar({
  policyList,
  categoryList,
  showAutoPullMessage,
  setUploadFiles,
  showUploadDialog,
  search,
  setSearch,
  autoPullDisabled,
  refreshData,
  showAlertToast,
}: {
  policyList: Policy[];
  categoryList: string[];
  showAutoPullMessage: any;
  setUploadFiles: any;
  showUploadDialog: any;
  search: string;
  setSearch: any;
  autoPullDisabled: boolean;
  refreshData: () => void;
  showAlertToast: (type: string, message: string) => void;
}) {
  const fileInputRef: { current: any } = useRef(null);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const showFilePicker = () => {
    fileInputRef.current.value = null;
    fileInputRef.current.click();
  };

  const handleFileSelect = (event: any) => {
    const selectedFileSize = event.target.files[0].size;
    if (selectedFileSize > maxUploadSize) {
      showAlertToast(
        'Error',
        `File is too big. Max size is ${
          parseInt(maxUploadSize) / 1000 / 1000
        }Mb.`
      );
      return;
    }
    setUploadFiles(event.target.files);
    showUploadDialog();
  };

  return (
    <>
      <div className={styles.controlBar}>
        <Search
          search={(event: any) => setSearch(event.target.value)}
          searchValue={search}
        />
        <div className={styles.buttonContainer}>
          <Tooltip
            message={`We can't automatically get the latest documents for this customer.
Please download them from the carrier and upload them here.`}
            disabled={!autoPullDisabled}
            position={'auto'}
            theme={'crm'}
          >
            <Button
              callback={() => setDialogOpen(true)}
              type={'primary'}
              theme={'crm'}
              icon={'sparkle'}
              disabled={autoPullDisabled}
            >
              Get the latest
            </Button>
          </Tooltip>
          <CarrierButton
            type={'secondary'}
            icon={'open'}
            policyList={policyList}
          >
            Go to carrier
          </CarrierButton>
          <Tooltip
            message={`You can also drag
documents into Fusion.`}
            position={'auto'}
            theme={'crm'}
          >
            <Button
              callback={showFilePicker}
              type={'secondary'}
              theme={'crm'}
              icon={'arrow_upload'}
            >
              Upload
            </Button>
          </Tooltip>
          <input
            type="file"
            className="hidden"
            ref={fileInputRef}
            onChange={handleFileSelect}
            accept="image/*, video/*, .doc, .docx, .pdf, .xml, .txt, .csv, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          />
        </div>
      </div>
      <AutoPull
        open={dialogOpen}
        closeDialog={() => setDialogOpen(false)}
        showAutoPullMessage={showAutoPullMessage}
        policyList={policyList}
        refreshData={refreshData}
      />
    </>
  );
}
