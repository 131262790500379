import { useEffect, useState } from 'react';
import { getRaterIntegration } from '../../services/network/dynamics';
import { mapRaterIntegrationFromRecordSet } from '../../services/utils';
import {
  RaterIntegration,
  RaterIntegrationResponse,
} from '../../models/raterIntegration';
import { useMsal } from '@azure/msal-react';
import { getAccessToken } from '../../../../components/common/utils';
import {
  RaterProductEnabled,
  INSURANCE_PRODUCT_TO_CRM_PRODUCT,
} from '@/services/forms/models/rateProductTypes';
import {
  InsuranceProduct,
  InsuranceProductEnum,
  Raters,
  RatersEnum,
} from '@bwinsurance/meta-rater-types';

export const useRaterIntegration = ({
  loading,
  crmAgentId,
}: {
  loading: boolean;
  crmAgentId?: string;
}) => {
  const { instance } = useMsal();

  const raterProductEnabledDefaults: RaterProductEnabled[] = [
    {
      rater: RatersEnum.enum.QuoteRush,
      product: InsuranceProductEnum.enum.Home,
      enabled: false,
    },
    {
      rater: RatersEnum.enum.EZLynx,
      product: InsuranceProductEnum.enum.Home,
      enabled: false,
    },
    {
      rater: RatersEnum.enum.EZLynx,
      product: InsuranceProductEnum.enum.Auto,
      enabled: false,
    },
  ];
  const [raterProductEnabled, setRaterProductEnabled] = useState<
    RaterProductEnabled[]
  >(raterProductEnabledDefaults);

  const isRaterEnabled = (
    product: InsuranceProduct,
    rater: Raters,
    raterIntegrations: RaterIntegration[]
  ) => {
    const crmProductDisplay = INSURANCE_PRODUCT_TO_CRM_PRODUCT[product];
    const raterConfig = raterIntegrations.find(
      (config: RaterIntegration) =>
        config.product.toUpperCase() === crmProductDisplay.toUpperCase() &&
        config.rater.toUpperCase() === rater.toUpperCase()
    );
    if (!raterConfig) {
      console.log(`Rater Integration Info not found for ${rater} ${product}`);
      return false;
    }
    return true;
  };

  const loadRaterIntegration = async (agentId: string) => {
    try {
      const accessToken = await getAccessToken(instance);

      if (!accessToken) {
        throw new Error('Failed to get access token');
      }
      const result: RaterIntegrationResponse[] | null =
        await getRaterIntegration(agentId, accessToken);
      const raterIntegrations: RaterIntegration[] = (result || []).map(
        mapRaterIntegrationFromRecordSet
      );

      const newRaterProductEnabled = raterProductEnabledDefaults.map(
        ({ rater, product }) => {
          return {
            rater,
            product,
            enabled: isRaterEnabled(product, rater, raterIntegrations),
          };
        }
      );

      setRaterProductEnabled(newRaterProductEnabled);
    } catch (error) {
      console.log('Failed to load rater integration data', error);
      setRaterProductEnabled(raterProductEnabledDefaults);
    }
  };

  useEffect(() => {
    if (!loading && crmAgentId) {
      loadRaterIntegration(crmAgentId);
    }
  }, [loading, crmAgentId]);

  return { raterProductEnabled };
};
