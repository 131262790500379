import { ReactElement } from 'react';
import Checkmark from './Checkmark';
import styles from './icon.module.css';
import '../../theme.module.css';
import Search from './Search';
import Sparkle from './Sparkle';
import Spinner from './Spinner';
import Open from './Open';
import KeyboardArrowDown from './KeyboardArrowDown';
import KeyboardArrowUp from './KeyboardArrowUp';
import ArrowUpload from './ArrowUpload';
import Mail from './Mail';
import Edit from './Edit';
import Note from './Note';
import Tag from './Tag';
import Link from './Link';
import Dismiss from './Dismiss';
import ErrorCircle from './ErrorCircle';

export type IconType =
  | 'arrow_upload'
  | 'checkmark'
  | 'dismiss'
  | 'edit'
  | 'error_circle'
  | 'keyboard_arrow_down'
  | 'keyboard_arrow_up'
  | 'link'
  | 'mail'
  | 'note'
  | 'open'
  | 'search'
  | 'sparkle'
  | 'spinner'
  | 'tag';
export type IconColor =
  | 'white'
  | 'black'
  | 'grey-300'
  | 'text-primary'
  | 'text-secondary'
  | 'error';

interface Props {
  type: IconType;
  color: IconColor;
  size?: number;
  viewbox?: string;
}

const Icon: React.FC<Props> = ({
  type,
  color,
  size = 24,
  viewbox = '0 0 24 24',
}) => {
  let iconPaths: ReactElement;
  switch (type) {
    case 'arrow_upload':
      iconPaths = <ArrowUpload />;
      break;
    case 'checkmark':
      iconPaths = <Checkmark />;
      break;
    case 'dismiss':
      iconPaths = <Dismiss />;
      break;
    case 'edit':
      iconPaths = <Edit />;
      break;
    case 'error_circle':
      iconPaths = <ErrorCircle />;
      break;
    case 'keyboard_arrow_down':
      iconPaths = <KeyboardArrowDown />;
      break;
    case 'keyboard_arrow_up':
      iconPaths = <KeyboardArrowUp />;
      break;
    case 'link':
      iconPaths = <Link />;
      break;
    case 'mail':
      iconPaths = <Mail />;
      break;
    case 'note':
      iconPaths = <Note />;
      break;
    case 'open':
      iconPaths = <Open />;
      break;
    case 'search':
      iconPaths = <Search />;
      break;
    case 'sparkle':
      iconPaths = <Sparkle />;
      break;
    case 'spinner':
      iconPaths = <Spinner />;
      break;
    case 'tag':
      iconPaths = <Tag />;
      break;
  }

  return (
    <svg
      className={styles[color]}
      width={size}
      height={size}
      viewBox={viewbox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {iconPaths}
    </svg>
  );
};

export default Icon;
