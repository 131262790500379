import { useEffect, useRef, useState } from 'react';
import { Policy } from '../../pages/documents/models/policy';
import Button from './button';
import styles from './carrierButton.module.css';
import '../../theme.module.css';
import Icon from '../Icon';

export default function CarrierButton({
  children,
  type,
  disabled = false,
  icon,
  classes = '',
  policyList,
}: {
  children: any;
  type:
    | 'primary-xl'
    | 'primary'
    | 'secondary-xl'
    | 'secondary'
    | 'text'
    | 'link';
  disabled?: boolean;
  icon?: string;
  classes?: string;
  policyList: Policy[];
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dropdownPosition, setDropdownPosition] = useState<number>(0);
  const dropdownRef: { current: any } = useRef(null);

  // this sets the height of the select dropdown
  // to make sure it doesn't overflow the window
  useEffect(() => {
    function updateDropdownPosition() {
      if (dropdownRef.current) {
        const rect = dropdownRef.current.getBoundingClientRect();
        setDropdownPosition(window.innerHeight - rect.bottom - 20);
      }
    }

    window.addEventListener('resize', updateDropdownPosition);
    updateDropdownPosition();
    return () => window.removeEventListener('resize', updateDropdownPosition);
  }, []);

  return (
    <div ref={dropdownRef}>
      {policyList.length < 2 && (
        <a
          href={
            policyList.length && policyList[0].carrier.agentPortalLink !== ''
              ? policyList[0].carrier.agentPortalLink
              : ''
          }
          target="_blank"
          onClick={() => setIsOpen(false)}
          rel="noreferrer"
        >
          <Button
            type={type}
            theme={'crm'}
            icon={icon}
            classes={`${styles.carrierButton} ${classes}`}
            disabled={
              disabled ||
              policyList.length < 1 ||
              policyList[0].carrier.agentPortalLink === ''
            }
          >
            Go to {policyList.length ? policyList[0].carrier.name : 'carrier'}
          </Button>
        </a>
      )}
      {policyList.length > 1 && (
        <Button
          callback={() => setIsOpen(!isOpen)}
          type={type}
          theme={'crm'}
          icon={icon}
          classes={`${styles.carrierButton} ${classes}`}
          disabled={disabled}
        >
          {children}
          <div className={isOpen ? styles.flip : undefined}>
            <Icon type={'keyboard_arrow_down'} color={'black'} size={18} />
          </div>
        </Button>
      )}
      {isOpen && (
        <>
          <div
            className={styles.overlay}
            onClick={() => setIsOpen(false)}
          ></div>
          <div className={styles.dropdownContainer}>
            <div
              style={{ maxHeight: dropdownPosition }}
              className={styles.dropdown}
            >
              {policyList
                .sort((a: Policy, b: Policy) =>
                  a.carrier.name < b.carrier.name ? -1 : 1
                )
                .map((policy: Policy, index: number) =>
                  policy.carrier.agentPortalLink ? (
                    <a
                      className={styles.policyLink}
                      href={policy.carrier.agentPortalLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span
                        onClick={() => setIsOpen(false)}
                      >{`${policy.carrier.name} (${policy.policyNumber})`}</span>
                    </a>
                  ) : (
                    <div className={styles.policy}>
                      <span>{`${policy.carrier.name} (${policy.policyNumber})`}</span>
                    </div>
                  )
                )}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
