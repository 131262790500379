import { Form } from '../../models/form';
import FormListSection from './FormListSection';
import Checkbox from '@/components/shared/checkbox';
import { useFormContext } from '../form/FormContext';
import styles from './FormList.module.css';

const FormList: React.FC = () => {
  const { toggleFormKey, selectedFormListKeys, forms } = useFormContext();

  return (
    <div className={styles['form-list']}>
      {forms.map((form: Form) => {
        const hasFormPolicies = form.policies?.length
          ? form.policies.length > 0
          : false;
        if (hasFormPolicies) {
          return (
            <FormListSection
              header={form.name || ''}
              policiesList={form.policies ?? []}
              formKey={form.key}
            />
          );
        } else {
          //individual form
          const formIsSelected = selectedFormListKeys.includes(form.key);

          return (
            <div className={styles.form}>
              <Checkbox
                checked={formIsSelected}
                onChange={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  toggleFormKey(form.key);
                }}
                label={form.name}
                theme={'crm'}
              />
            </div>
          );
        }
      })}
    </div>
  );
};

export default FormList;
