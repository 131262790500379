export const booleanDropdownOptions = [
  { key: 'true', display: 'Yes' },
  { key: 'false', display: 'No' },
];

export const constructionTypeOptions = [
  { key: 'A_FRAME', display: 'A frame' },
  { key: 'ADOBE', display: 'Adobe' },
  { key: 'ALUMINUM_FRAME', display: 'Aluminum frame' },
  { key: 'ALUMINUM_SIDING', display: 'Aluminum siding' },
  { key: 'ALUMINUM_VINYL', display: 'Aluminum vinyl' },
  { key: 'ARCHED_DOME', display: 'Arched dome' },
  { key: 'BLOCK_BRICK', display: 'Block brick' },
  { key: 'BLOCK_METAL', display: 'Block metal' },
  { key: 'BLOCK_STEEL', display: 'Block steel' },
  { key: 'BRICK', display: 'Brick' },
  { key: 'BRICK_CINDER_BLOCK', display: 'Brick cinder block' },
  { key: 'BRICK_CONCRETE', display: 'Brick concrete' },
  { key: 'BRICK_FRAME', display: 'Brick frame' },
  { key: 'BRICK_METAL', display: 'Brick metal' },
  { key: 'BRICK_STEEL', display: 'Brick steel' },
  { key: 'BRICK_STONE', display: 'Brick stone' },
  { key: 'BRICK_STUCCO', display: 'Brick stucco' },
  { key: 'CINDER_BLOCK', display: 'Cinder block' },
  { key: 'CLAPBOARD', display: 'Clapboard' },
  { key: 'COMBINATION', display: 'Combination' },
  { key: 'CONCRETE', display: 'Concrete' },
  { key: 'CONCRETE_BLOCK', display: 'Concrete block' },
  { key: 'CONCRETE_MASONRY', display: 'Concrete masonry' },
  { key: 'CONCRETE_STEEL', display: 'Concrete steel' },
  { key: 'CUSTOM', display: 'Custom' },
  { key: 'DOME', display: 'Dome' },
  { key: 'FLEXIBLE_FLEXICORE', display: 'Flexible flexicore' },
  { key: 'FRAME', display: 'Frame' },
  { key: 'FRAME_MASONRY', display: 'Frame masonry' },
  { key: 'FRAME_STEEL', display: 'Frame steel' },
  { key: 'FRAME_STUCCO', display: 'Frame stucco' },
  { key: 'GLASS', display: 'Glass' },
  { key: 'HEAVY', display: 'Heavy' },
  { key: 'LIGHT', display: 'Light' },
  { key: 'LOG', display: 'Log' },
  { key: 'MANUFACTURED_MODULAR', display: 'Manufactured modular' },
  { key: 'MASONRY', display: 'Masonry' },
  { key: 'MASONRY_TILT_UP', display: 'Masonry tilt up' },
  { key: 'METAL', display: 'Metal' },
  { key: 'NONE', display: 'None' },
  { key: 'POLE_FRAME', display: 'Pole frame' },
  { key: 'PREFAB_METAL', display: 'Prefab metal' },
  { key: 'STEEL', display: 'Steel' },
  { key: 'STEEL_MASONRY', display: 'Steel masonry' },
  { key: 'STEEL_STUCCO', display: 'Steel stucco' },
  { key: 'STEEL_WOOD', display: 'Steel wood' },
  { key: 'STONE_VENEER', display: 'Stone veneer' },
  { key: 'STONE_ROCK', display: 'Stone rock' },
  { key: 'STUCCO', display: 'Stucco' },
  { key: 'TILT_UP', display: 'Tilt up' },
  { key: 'TILT_UP_BRICK_METAL', display: 'Tilt up brick metal' },
  { key: 'TILT_UP_CONCRETE', display: 'Tilt up concrete' },
  { key: 'TYPE_NOT_SPECIFIED', display: 'Type not specified' },
  { key: 'TYPE_UNKNOWN', display: 'Type unknown' },
  { key: 'WOOD', display: 'Wood' },
  { key: 'WOOD_FRAME', display: 'Wood frame' },
  {
    key: 'WOOD_FRAME_CONCRETE_BLOCK',
    display: 'Wood frame concrete block',
  },
  { key: 'WOOD_FRAME_METAL', display: 'Wood frame metal' },
  { key: 'WOOD_BRICK', display: 'Wood brick' },
  { key: 'WOOD_STEEL', display: 'Wood steel' },
  { key: 'WOOD_STONE', display: 'Wood stone' },
  { key: 'WOOD_STUCCO', display: 'Wood stucco' },
];

export const dogBreedOptions = [
  { key: 'AKITA', display: 'Akita' },
  { key: 'AMERICAN_BULLDOG', display: 'American Bulldog' },
  { key: 'BEAUCERON', display: 'Beauceron' },
  { key: 'CAUCASIAN_MOUNTAIN_DOG', display: 'Caucasian Mountain Dog' },
  { key: 'CHOW', display: 'Chow' },
  { key: 'DOBERMAN_PINSCHER', display: 'Doberman Pinscher' },
  { key: 'GERMAN_SHEPHERD', display: 'German Shepherd' },
  { key: 'GREAT_DANE', display: 'Great Dane' },
  { key: 'PIT_BULL', display: 'Pit Bull' },
  { key: 'PRESA_CANARIO', display: 'Presa Canario' },
  { key: 'ROTTWEILER', display: 'Rottweiler' },
  { key: 'STAFFORDSHIRE_TERRIER', display: 'Staffordshire Terrier' },
  { key: 'WOLF', display: 'Wolf' },
  { key: 'NONE_OF_THE_ABOVE', display: 'None of the above' },
];

export const dwellingTypeOptions = [
  { key: 'One Family', display: 'One family' },
  { key: 'Two Family', display: 'Two family' },
  { key: 'Three Family', display: 'Three family' },
  { key: 'Four Family', display: 'Four family' },
];

export const dwellingUseOptions = [
  { key: 'Primary', display: 'Primary' },
  { key: 'Secondary', display: 'Secondary' },
  { key: 'Seasonal', display: 'Seasonal' },
  { key: 'Farm', display: 'Farm' },
  { key: 'Unoccupied', display: 'Unoccupied' },
  { key: 'Vacant', display: 'Vacant' },
  { key: 'COC', display: 'COC' },
];

export const heatingTypeOptions = [
  { key: 'BASEBOARD', display: 'Baseboard' },
  { key: 'CEILING_CABLE', display: 'Ceiling cable' },
  { key: 'CEILING_FURNACE', display: 'Ceiling furnace' },
  { key: 'CENTRAL', display: 'Central' },
  { key: 'COAL', display: 'Coal' },
  { key: 'CONVECTION', display: 'Convection' },
  { key: 'ELECTRIC', display: 'Electric' },
  { key: 'FIREPLACE', display: 'Fireplace' },
  { key: 'FLOOR_FURNACE', display: 'Floor furnace' },
  { key: 'FLOOR_WALL_FURNACE', display: 'Floor wall furnace' },
  { key: 'FORCED_AIR', display: 'Forced air' },
  {
    key: 'FORCED_AIR_WITH_AIR_CONDITIONING',
    display: 'Forced air with air conditioning',
  },
  { key: 'GAS', display: 'Gas' },
  { key: 'GEO_THERMAL', display: 'Geo thermal' },
  { key: 'GRAVITY', display: 'Gravity' },
  { key: 'HEAT_PUMP', display: 'Heat pump' },
  { key: 'HOT_WATER', display: 'Hot water' },
  { key: 'NONE', display: 'None' },
  { key: 'NOT_AVAILABLE', display: 'Not available' },
  { key: 'NP', display: 'Np' },
  { key: 'OIL', display: 'Oil' },
  { key: 'OTHER', display: 'Other' },
  { key: 'PACKAGE', display: 'Package' },
  { key: 'PARTIAL', display: 'Partial' },
  { key: 'PROPANE', display: 'Propane' },
  { key: 'RADIANT', display: 'Radiant' },
  { key: 'SOLAR', display: 'Solar' },
  { key: 'SPACE', display: 'Space' },
  { key: 'STEAM', display: 'Steam' },
  { key: 'STOVE', display: 'Stove' },
  { key: 'UNKNOWN', display: 'Unknown' },
  { key: 'WALL_GAS', display: 'Wall gas' },
  { key: 'WOOD_FURNACE', display: 'Wood furnace' },
  { key: 'YES', display: 'Yes' },
  { key: 'ZONE', display: 'Zone' },
];

export const homeUpdateOptions = [
  { key: 'NOT UPDATED', display: 'Not updated' },
  { key: 'PARTIAL UPDATE', display: 'Partial update' },
  { key: 'COMPLETE UPDATE', display: 'Complete update' },
];

export const roofShapeOptions = [
  { key: 'A_FRAME', display: 'A frame' },
  { key: 'ARCHED', display: 'Arched' },
  { key: 'BARN', display: 'Barn' },
  { key: 'BARREL', display: 'Barrel' },
  { key: 'BOWSTRING_TRUSS', display: 'Bowstring truss' },
  { key: 'BUBBLE', display: 'Bubble' },
  { key: 'BUTTERFLY', display: 'Butterfly' },
  { key: 'CANOPY', display: 'Canopy' },
  { key: 'CATHEDRAL_CLERESTORY', display: 'Cathedral clerestory' },
  { key: 'COMPLEX_CUSTOM', display: 'Complex custom' },
  { key: 'CONTEMPORARY', display: 'Contemporary' },
  { key: 'DORMER', display: 'Dormer' },
  { key: 'FLAT', display: 'Flat' },
  { key: 'FRAME', display: 'Frame' },
  { key: 'GABLE', display: 'Gable' },
  { key: 'GABLE_HIP', display: 'Gable hip' },
  { key: 'GAMBREL', display: 'Gambrel' },
  { key: 'GAMBREL_MANSARD', display: 'Gambrel mansard' },
  { key: 'GEODESIC_DOME', display: 'Geodesic dome' },
  { key: 'HIP', display: 'Hip' },
  { key: 'MANSARD', display: 'Mansard' },
  { key: 'MONITOR', display: 'Monitor' },
  { key: 'OTHER', display: 'Other' },
  { key: 'PITCHED', display: 'Pitched' },
  { key: 'PRESTRESS_CONCRETE', display: 'Prestress concrete' },
  { key: 'PYRAMID', display: 'Pyramid' },
  { key: 'REINFORCED_CONCRETE', display: 'Reinforced concrete' },
  { key: 'RIGID_FRM_BAR_JT', display: 'Rigid frm bar jt' },
  { key: 'SAWTOOTH', display: 'Sawtooth' },
  { key: 'SHED', display: 'Shed' },
  { key: 'STEEL_FRM_TRUSS', display: 'Steel frm truss' },
  { key: 'SWISS_CHALET_ALPINE', display: 'Swiss chalet alpine' },
  { key: 'WOOD_TRUSS', display: 'Wood truss' },
];

export const roofTypeOptions = [
  { key: 'ALUMINUM', display: 'Aluminum' },
  { key: 'ASBESTOS', display: 'Asbestos' },
  { key: 'ASPHALT', display: 'Asphalt' },
  {
    key: 'AVERAGE_COMPOSITION_SHINGLE',
    display: 'Average composition shingle',
  },
  { key: 'BAR_TILE_SPANISH_STYLE', display: 'Bar tile spanish style' },
  { key: 'BI_METAL_TWO_PLY', display: 'Bi metal two ply' },
  {
    key: 'BUILTUP_LAYERED_ASPHALT',
    display: 'Builtup layered asphalt',
  },
  { key: 'CLAY_TILE', display: 'Clay tile' },
  { key: 'COMPOSITION_SHINGLE', display: 'Composition shingle' },
  { key: 'CONCRETE', display: 'Concrete' },
  { key: 'CONCRETE_TILE', display: 'Concrete tile' },
  { key: 'ENAMEL', display: 'Enamel' },
  { key: 'FIBERGLASS', display: 'Fiberglass' },
  { key: 'GALVANIZED', display: 'Galvanized' },
  { key: 'GYPSUM', display: 'Gypsum' },
  {
    key: 'HEAVY_COMPOSITION_SHINGLE',
    display: 'Heavy composition shingle',
  },
  {
    key: 'LIGHT_COMPOSITION_SHINGLE',
    display: 'Light composition shingle',
  },
  { key: 'MASONITE_CEMENT_SHAKE', display: 'Masonite cement shake' },
  { key: 'MEDIUM_SHAKE', display: 'Medium shake' },
  { key: 'METAL', display: 'Metal' },
  { key: 'NOT_AVAILABLE', display: 'Not available' },
  { key: 'NP', display: 'Np' },
  { key: 'OTHER_NOT_CLASSIFIED', display: 'Other not classified' },
  { key: 'ROCK_GRAVEL', display: 'Rock gravel' },
  {
    key: 'ROLL_COMPOSITION_ROLLED_MINERAL_ROOF',
    display: 'Roll composition rolled mineral roof',
  },
  {
    key: 'ROLL_PAPER_ROLLED_SMOOTH_ROOF',
    display: 'Roll paper rolled smooth roof',
  },
  { key: 'ROLL_TAR_GRAVEL', display: 'Roll tar gravel' },
  { key: 'RUBBER_ELASTOMETRIC', display: 'Rubber elastometric' },
  { key: 'SHAKE', display: 'Shake' },
  { key: 'SHINGLE', display: 'Shingle' },
  { key: 'SLATE', display: 'Slate' },
  { key: 'SLATE_TILE', display: 'Slate tile' },
  { key: 'SYNTHETIC_TILE', display: 'Synthetic tile' },
  { key: 'TILE', display: 'Tile' },
  { key: 'UNKNOWN', display: 'Unknown' },
  {
    key: 'UNKNOWN_OR_NOT_PROVIDED',
    display: 'Unknown or not provided',
  },
  { key: 'URETHANE', display: 'Urethane' },
  { key: 'WOOD', display: 'Wood' },
  { key: 'WOOD_SHAKE_SHINGLE', display: 'Wood shake shingle' },
  { key: 'WOOD_SHINGLE', display: 'Wood shingle' },
];

export const subTypeOptions = [
  { key: 'AGRICULTURE_FARMING', display: 'Agriculture farming' },
  { key: 'COMMERCIAL', display: 'Commercial' },
  { key: 'INDUSTRIAL', display: 'Industrial' },
  { key: 'OTHER', display: 'Other' },
  { key: 'OTHER_UNKNOWN', display: 'Other unknown' },
  { key: 'PUBLIC_WORKS', display: 'Public works' },
  { key: 'RESIDENTIAL', display: 'Residential' },
  { key: 'VACANT_LAND', display: 'Vacant land' },
];

export const swimmingPoolTypesOptions = [
  { key: 'OTHER', display: 'Other' },
  { key: 'ABOVE_GROUND_POOL', display: 'Above ground pool' },
  { key: 'ADULT_KIDDIE_POOLS', display: 'Adult kiddie pools' },
  { key: 'BUMPER_BOAT', display: 'Bumper boat' },
  { key: 'CONCRETE_VINYL', display: 'Concrete vinyl' },
  { key: 'FISH_POND', display: 'Fish pond' },
  { key: 'HEATED_POOL_GUNITE', display: 'Heated pool gunite' },
  { key: 'INDOOR_POOL', display: 'Indoor pool' },
  { key: 'IN_GROUND_POOL', display: 'In ground pool' },
  { key: 'IN_GROUND_VINYL_POOL', display: 'In ground vinyl pool' },
  { key: 'KIDDIE_PLAY_POOL', display: 'Kiddie play pool' },
  { key: 'LAGOON', display: 'Lagoon' },
  { key: 'LAP_POOL', display: 'Lap pool' },
  {
    key: 'MULTIPLE_POOLS_UNSPECIFIED',
    display: 'Multiple pools unspecified',
  },
  { key: 'NO_POOL', display: 'No pool' },
  { key: 'NOT_STATED', display: 'Not stated' },
  { key: 'POOL_SPA_WITH_DECK', display: 'Pool spa with deck' },
  {
    key: 'POOL_CONCRETE_WITH_CABANA_POOL_HOUSE',
    display: 'Pool concrete with cabana pool house',
  },
  {
    key: 'POOL_CONCRETE_WITH_EQUIPMENT',
    display: 'Pool concrete with equipment',
  },
  { key: 'POOL_CONCRETE_FENCED', display: 'Pool concrete fenced' },
  {
    key: 'POOL_GUNITE_WITH_HOT_TUB_SPA',
    display: 'Pool gunite with hot tub spa',
  },
  { key: 'POOL_GUNITE_ENCLOSED', display: 'Pool gunite enclosed' },
  {
    key: 'POOL_GUNITE_KIDNEY_SHAPED',
    display: 'Pool gunite kidney shaped',
  },
  {
    key: 'POOL_IN_GROUND_WITH_HOT_TUB_SPA',
    display: 'Pool in ground with hot tub spa',
  },
  {
    key: 'POOL_UNSPECIFIED_WITH_COVER',
    display: 'Pool unspecified with cover',
  },
  {
    key: 'POOL_UNSPECIFIED_WITH_DECK',
    display: 'Pool unspecified with deck',
  },
  {
    key: 'POOL_UNSPECIFIED_ENCLOSED',
    display: 'Pool unspecified enclosed',
  },
  {
    key: 'POOL_UNSPECIFIED_FENCED',
    display: 'Pool unspecified fenced',
  },
  {
    key: 'POOL_UNSPECIFIED_HEATED',
    display: 'Pool unspecified heated',
  },
  { key: 'POOL_VINYL_WITH_DECK', display: 'Pool vinyl with deck' },
  { key: 'POOL_VINYL_WITH_PATIO', display: 'Pool vinyl with patio' },
  {
    key: 'POOL_VINYL_KIDNEY_SHAPED',
    display: 'Pool vinyl kidney shaped',
  },
  { key: 'POOL_VINYL_L_SHAPED', display: 'Pool vinyl l shaped' },
  { key: 'POOL_VINYL_OVAL_SHAPED', display: 'Pool vinyl oval shaped' },
  { key: 'POOL_WITH_BAR', display: 'Pool with bar' },
  {
    key: 'POOL_WITH_CABANA_POOL_HOUSE',
    display: 'Pool with cabana pool house',
  },
  { key: 'POOL_WITH_CANOPY', display: 'Pool with canopy' },
  { key: 'POOL_WITH_DIVING_BOARD', display: 'Pool with diving board' },
  { key: 'POOL_WITH_EQUIPMENT', display: 'Pool with equipment' },
  { key: 'POOL_WITH_FOUNTAIN', display: 'Pool with fountain' },
  { key: 'POOL_WITH_HOT_TUB_SPA', display: 'Pool with hot tub spa' },
  { key: 'POOL_WITH_PATIO', display: 'Pool with patio' },
  { key: 'POOL_WITH_SAUNA', display: 'Pool with sauna' },
  { key: 'POOL_WITH_WATERFALL', display: 'Pool with waterfall' },
  { key: 'POOL_ARROW_SHAPED', display: 'Pool arrow shaped' },
  {
    key: 'POOL_BRICK_MASONRY_STONE',
    display: 'Pool brick masonry stone',
  },
  { key: 'POOL_CIRCULAR', display: 'Pool circular' },
  { key: 'POOL_CONCRETE', display: 'Pool concrete' },
  { key: 'POOL_FIBERGLASS', display: 'Pool fiberglass' },
  { key: 'POOL_GUNITE', display: 'Pool gunite' },
  { key: 'POOL_IRREGULAR_SHAPED', display: 'Pool irregular shaped' },
  { key: 'POOL_KIDNEY_SHAPED', display: 'Pool kidney shaped' },
  { key: 'POOL_L_SHAPED', display: 'Pool l shaped' },
  { key: 'POOL_METAL', display: 'Pool metal' },
  { key: 'POOL_OVAL_SHAPED', display: 'Pool oval shaped' },
  {
    key: 'POOL_PLASTIC_VINYL_LINED',
    display: 'Pool plastic vinyl lined',
  },
  {
    key: 'POOL_PRE_FABRICATED_VINYL',
    display: 'Pool pre fabricated vinyl',
  },
  { key: 'POOL_SOLAR_HEATED', display: 'Pool solar heated' },
  { key: 'POOL_STONE_GRANITE', display: 'Pool stone granite' },
  {
    key: 'POOL_VINYL_LINING_STEEL_WALLED',
    display: 'Pool vinyl lining steel walled',
  },
  {
    key: 'POOLS_THREE_PLUS_UNSPECIFIED',
    display: 'Pools three plus unspecified',
  },
  { key: 'POOLS_TWO_UNSPECIFIED', display: 'Pools two unspecified' },
  {
    key: 'PUBLIC_MUNICIPAL_COMMERCIAL',
    display: 'Public municipal commercial',
  },
  { key: 'SAUNA_SPA_ONLY', display: 'Sauna spa only' },
  { key: 'SAUNA_ONLY', display: 'Sauna only' },
  { key: 'SPA_GAZEBO_ONLY', display: 'Spa gazebo only' },
  { key: 'SPA_HOT_TUB_DECK_ONLY', display: 'Spa hot tub deck only' },
  { key: 'SPA_HOT_TUB_ONLY', display: 'Spa hot tub only' },
  { key: 'TYPE_NOT_SPECIFIED', display: 'Type not specified' },
  { key: 'WADING_POOL', display: 'Wading pool' },
];

export const educationOptions = [
  { key: 'Unknown', display: 'Unknown' },
  { key: 'NoHighSchoolDiploma', display: 'No high school diploma' },
  { key: 'HighSchoolDiploma', display: 'High school diploma' },
  { key: 'SomeCollegeNoDegree', display: 'Some college no degree' },
  {
    key: 'VocationalTechnicalDegree',
    display: 'Vocational technical degree',
  },
  { key: 'AssociatesDegree', display: 'Associates degree' },
  { key: 'Bachelors', display: 'Bachelors' },
  { key: 'Masters', display: 'Masters' },
  { key: 'Phd', display: 'Phd' },
  { key: 'MedicalDegree', display: 'Medical degree' },
  { key: 'LawDegree', display: 'Law degree' },
];

export const genderOptions = [
  { key: 'Unknown', display: 'Unknown' },
  { key: 'Female', display: 'Female' },
  { key: 'Male', display: 'Male' },
  { key: 'NotSpecified', display: 'Not specified' },
  { key: 'NonBinary', display: 'Non-binary' },
];

export const maritalStatusOptions = [
  { key: 'Unknown', display: 'Unknown' },
  { key: 'Single', display: 'Single' },
  { key: 'Married', display: 'Married' },
  { key: 'DomesticPartner', display: 'Domestic partner' },
  { key: 'Widowed', display: 'Widowed' },
  { key: 'Separated', display: 'Separated' },
  { key: 'Divorced', display: 'Divorced' },
];

export const licenseStatusOptions = [
  { key: 'Valid', display: 'Valid' },
  { key: 'Permit', display: 'Permit' },
  { key: 'Expired', display: 'Expired' },
  { key: 'Suspended', display: 'Suspended' },
  { key: 'Cancelled', display: 'Cancelled' },
  { key: 'Not Licensed', display: 'Not licensed' },
  { key: 'Permanently Revoked', display: 'Permanently revoked' },
];

export const interestOptions = [
  { key: 'Home', display: 'Home' },
  { key: 'Auto', display: 'Auto' },
];

export const employmentStatusOptions = [
  { key: 'Employed', display: 'Employed' },
  { key: 'Unemployed', display: 'Unemployed' },
];

export const applicantTypeOptions = [
  { key: 'Applicant', display: 'Applicant' },
  { key: 'CoApplicant', display: 'CoApplicant' },
];

export const relationOptions = [
  { key: 'Child', display: 'Child' },
  { key: 'Domestic Partner', display: 'Domestic partner' },
  { key: 'Employee', display: 'Employee' },
  { key: 'Insured', display: 'Insured' },
  { key: 'Other', display: 'Other' },
  { key: 'Parent', display: 'Parent' },
  { key: 'Relative', display: 'Relative' },
  { key: 'Spouse', display: 'Spouse' },
];

export const vehicleUsageOptions = [
  { key: 'Business', display: 'Business' },
  { key: 'Farming', display: 'Farming' },
  { key: 'Pleasure', display: 'Pleasure' },
  { key: 'To/From Work', display: 'To/From Work' },
  { key: 'To/From School', display: 'To/From School' },
];

export const vehicleOwnershipOptions = [
  { key: 'Owned', display: 'Owned' },
  { key: 'Leased', display: 'Leased' },
  { key: 'Lien', display: 'Lien' },
];

export const homeOwnershipOptions = [
  { key: 'Home (owned)', display: 'Home (owned)' },
  { key: 'Condo (owned)', display: 'Condo (owned)' },
  { key: 'Apartment', display: 'Apartment' },
  { key: 'Rental Home/Condo', display: 'Rental Home/Condo' },
  { key: 'Mobile Home', display: 'Mobile Home' },
  { key: 'Live With Parents', display: 'Live With Parents' },
  { key: 'Other', display: 'Other' },
];

export const yearsDropDownOptions = [
  { key: '6 months or less', display: '6 months or less' },
  { key: '6-12 months', display: '6-12 months' },
  { key: '1 year', display: '1 year' },
  { key: '2 years', display: '2 years' },
  { key: '3 years', display: '3 years' },
  { key: '4 years', display: '4 years' },
  { key: '5 years', display: '5 years' },
  { key: '6 years', display: '6 years' },
  { key: '7 years', display: '7 years' },
  { key: '8 years', display: '8 years' },
  { key: '9 years', display: '9 years' },
  { key: '10 years', display: '10 years' },
  { key: 'More than 10 years', display: 'More than 10 years' },
];

export const monthsDropDownOptions = [
  { key: '6', display: '6 months or less' },
  { key: '11', display: '6-12 months' },
  { key: '12', display: '1 year' },
  { key: '24', display: '2 years' },
  { key: '36', display: '3 years' },
  { key: '48', display: '4 years' },
  { key: '60', display: '5 years' },
  { key: '72', display: '6 years' },
  { key: '84', display: '7 years' },
  { key: '96', display: '8 years' },
  { key: '108', display: '9 years' },
  { key: '120', display: '10 years' },
  { key: '121', display: 'More than 10 years' },
];

export const HomePriorCarrierEnum = [
  { key: 'Other Standard', display: 'Other Standard' },
  { key: 'Other Non-Standard', display: 'Other Non-Standard' },
  { key: 'No Prior Insurance', display: 'No Prior Insurance' },
  { key: '21st Century', display: '21st Century' },
  { key: 'AAA', display: 'AAA' },
  { key: 'AAANCNU', display: 'AAANCNU' },
  { key: 'AARP', display: 'AARP' },
  {
    key: 'Access Home Insurance Company',
    display: 'Access Home Insurance Company',
  },
  { key: 'Acuity', display: 'Acuity' },
  {
    key: 'Adirondack Ins Exchange',
    display: 'Adirondack Ins Exchange',
  },
  { key: 'Aegis', display: 'Aegis' },
  { key: 'AIG', display: 'AIG' },
  { key: 'Alfa Alliance', display: 'Alfa Alliance' },
  {
    key: 'Allegany Co-op Insurance Company',
    display: 'Allegany Co-op Insurance Company',
  },
  { key: 'Allianz of America', display: 'Allianz of America' },
  {
    key: 'Allianz of America-Jefferson',
    display: 'Allianz of America-Jefferson',
  },
  { key: 'Allied Trust', display: 'Allied Trust' },
  { key: 'Allied', display: 'Allied' },
  { key: 'Allmerica', display: 'Allmerica' },
  { key: 'Allstate', display: 'Allstate' },
  { key: 'America First', display: 'America First' },
  { key: 'American Commerce', display: 'American Commerce' },
  { key: 'American Family', display: 'American Family' },
  {
    key: 'American Freedom Insurance Company',
    display: 'American Freedom Insurance Company',
  },
  { key: 'American Integrity', display: 'American Integrity' },
  {
    key: 'American Risk Insurance',
    display: 'American Risk Insurance',
  },
  { key: 'American Traditions', display: 'American Traditions' },
  { key: 'Amica', display: 'Amica' },
  { key: 'AmShield', display: 'AmShield' },
  { key: 'Anchor Insurance', display: 'Anchor Insurance' },
  { key: 'Andover Companies', display: 'Andover Companies' },
  { key: 'ASI Lloyds', display: 'ASI Lloyds' },
  { key: 'Atlantic Mutual', display: 'Atlantic Mutual' },
  { key: 'Atlas General Agency', display: 'Atlas General Agency' },
  { key: 'Austin Mutual', display: 'Austin Mutual' },
  { key: 'Auto-Owners', display: 'Auto-Owners' },
  {
    key: 'Avatar Property and Casualty Insurance',
    display: 'Avatar Property and Casualty Insurance',
  },
  {
    key: 'Badger Mutual Insurance Company',
    display: 'Badger Mutual Insurance Company',
  },
  { key: 'Badger Mutual', display: 'Badger Mutual' },
  { key: 'Balboa', display: 'Balboa' },
  { key: 'Bamboo Insurance', display: 'Bamboo Insurance' },
  { key: 'Bankers', display: 'Bankers' },
  { key: 'Beacon National', display: 'Beacon National' },
  { key: 'Bear River Mutual', display: 'Bear River Mutual' },
  {
    key: 'Berkshire Hathaway GUARD',
    display: 'Berkshire Hathaway GUARD',
  },
  {
    key: 'Brethren Mutual Insurance Company',
    display: 'Brethren Mutual Insurance Company',
  },
  { key: 'Bunker Hill', display: 'Bunker Hill' },
  { key: 'Cabrillo Coastal', display: 'Cabrillo Coastal' },
  { key: 'California Casualty', display: 'California Casualty' },
  {
    key: 'Capital Insurance Group',
    display: 'Capital Insurance Group',
  },
  { key: 'Capitol Preferred', display: 'Capitol Preferred' },
  { key: 'Celina', display: 'Celina' },
  { key: 'Centauri', display: 'Centauri' },
  { key: 'Central Mutual of OH', display: 'Central Mutual of OH' },
  { key: 'Century National', display: 'Century National' },
  {
    key: 'Chautauqua Patrons Insurance Company',
    display: 'Chautauqua Patrons Insurance Company',
  },
  { key: 'Chubb', display: 'Chubb' },
  { key: 'Cincinnati Casualty', display: 'Cincinnati Casualty' },
  { key: 'Cincinnati Insurance', display: 'Cincinnati Insurance' },
  { key: 'Citizens', display: 'Citizens' },
  { key: 'CNA', display: 'CNA' },
  { key: 'Colorado Casualty', display: 'Colorado Casualty' },
  { key: 'Commonwealth', display: 'Commonwealth' },
  { key: 'Community Mutual', display: 'Community Mutual' },
  {
    key: 'Concord Group Insurance',
    display: 'Concord Group Insurance',
  },
  { key: 'Consumers Insurance', display: 'Consumers Insurance' },
  {
    key: 'Countryway Insurance Co',
    display: 'Countryway Insurance Co',
  },
  { key: 'CSE', display: 'CSE' },
  { key: 'Cumberland', display: 'Cumberland' },
  { key: 'Cypress', display: 'Cypress' },
  { key: 'Dairyland', display: 'Dairyland' },
  {
    key: 'Delta Lloyds Insurance Company',
    display: 'Delta Lloyds Insurance Company',
  },
  { key: 'Donegal', display: 'Donegal' },
  {
    key: 'Edison Insurance Company',
    display: 'Edison Insurance Company',
  },
  { key: 'Electric', display: 'Electric' },
  { key: 'EMC', display: 'EMC' },
  { key: 'Encompass', display: 'Encompass' },
  { key: 'Encova Exceed', display: 'Encova Exceed' },
  { key: 'Enumclaw Insurance', display: 'Enumclaw Insurance' },
  { key: 'Erie', display: 'Erie' },
  { key: 'Esurance', display: 'Esurance' },
  {
    key: 'Excelsior Insurance Company',
    display: 'Excelsior Insurance Company',
  },
  { key: 'Fair Plan', display: 'Fair Plan' },
  { key: 'Farm Bureau', display: 'Farm Bureau' },
  { key: 'Farmers of Salem', display: 'Farmers of Salem' },
  { key: 'Farmers', display: 'Farmers' },
  {
    key: 'Farmers Fire Insurance Company',
    display: 'Farmers Fire Insurance Company',
  },
  {
    key: 'FedNat Insurance Company',
    display: 'FedNat Insurance Company',
  },
  { key: 'Fidelity', display: 'Fidelity' },
  { key: 'Firemans Fund', display: 'Firemans Fund' },
  { key: 'First American', display: 'First American' },
  { key: 'Flagship Insurance', display: 'Flagship Insurance' },
  { key: 'Florida Family', display: 'Florida Family' },
  { key: 'Florida Peninsula', display: 'Florida Peninsula' },
  { key: 'Florida Specialty', display: 'Florida Specialty' },
  {
    key: 'Foremost Insurance Company',
    display: 'Foremost Insurance Company',
  },
  {
    key: 'Frankenmuth Mutual Insurance Company',
    display: 'Frankenmuth Mutual Insurance Company',
  },
  { key: 'Fremont Insurance', display: 'Fremont Insurance' },
  { key: 'Frontline Insurance', display: 'Frontline Insurance' },
  { key: 'Geico', display: 'Geico' },
  { key: 'General Casualty', display: 'General Casualty' },
  { key: 'Germantown Mutual', display: 'Germantown Mutual' },
  { key: 'Germania Insurance', display: 'Germania Insurance' },
  { key: 'GMAC', display: 'GMAC' },
  { key: 'Goodville Mutual', display: 'Goodville Mutual' },
  { key: 'Grange', display: 'Grange' },
  { key: 'GRE/Go America', display: 'GRE/Go America' },
  { key: 'Great American', display: 'Great American' },
  { key: 'Grinnell', display: 'Grinnell' },
  { key: 'Guide One', display: 'Guide One' },
  { key: 'GulfStream', display: 'GulfStream' },
  {
    key: 'Hallmark Insurance Company',
    display: 'Hallmark Insurance Company',
  },
  { key: 'Hanover', display: 'Hanover' },
  { key: 'Harleysville', display: 'Harleysville' },
  { key: 'Hartford OMNI', display: 'Hartford OMNI' },
  { key: 'Hartford', display: 'Hartford' },
  { key: 'Hastings Mutual', display: 'Hastings Mutual' },
  { key: 'Hawkeye Security', display: 'Hawkeye Security' },
  { key: 'Heritage P/C', display: 'Heritage P/C' },
  { key: 'Hippo Insurance', display: 'Hippo Insurance' },
  {
    key: 'Hochheim Prairie Insurance',
    display: 'Hochheim Prairie Insurance',
  },
  { key: 'Homeowners of America', display: 'Homeowners of America' },
  { key: 'Horace Mann', display: 'Horace Mann' },
  { key: 'Houston General', display: 'Houston General' },
  {
    key: 'Imperial Fire and Casualty Insurance',
    display: 'Imperial Fire and Casualty Insurance',
  },
  { key: 'Indiana Farmers', display: 'Indiana Farmers' },
  { key: 'Indiana', display: 'Indiana' },
  { key: 'Insurors Indemnity', display: 'Insurors Indemnity' },
  { key: 'Integon', display: 'Integon' },
  { key: 'Integrity', display: 'Integrity' },
  {
    key: 'Interboro Insurance Company',
    display: 'Interboro Insurance Company',
  },
  { key: 'Kemper', display: 'Kemper' },
  { key: 'LeMars Insurance', display: 'LeMars Insurance' },
  { key: 'Lemonade', display: 'Lemonade' },
  { key: 'Liberty Mutual', display: 'Liberty Mutual' },
  { key: 'Liberty Northwest', display: 'Liberty Northwest' },
  { key: 'LightHouse', display: 'LightHouse' },
  { key: 'Lititz Mutual', display: 'Lititz Mutual' },
  {
    key: 'Livingston Mutual Insurance Company',
    display: 'Livingston Mutual Insurance Company',
  },
  { key: 'Lloyds', display: 'Lloyds' },
  {
    key: 'Madison Mutual Insurance Company',
    display: 'Madison Mutual Insurance Company',
  },
  {
    key: 'Maidstone Insurance Company',
    display: 'Maidstone Insurance Company',
  },
  { key: 'Main Street America', display: 'Main Street America' },
  { key: 'Maison Insurance', display: 'Maison Insurance' },
  { key: 'MAPFRE', display: 'MAPFRE' },
  {
    key: 'MAX - MutualAid eXchange',
    display: 'MAX - MutualAid eXchange',
  },
  { key: 'Merchants Group', display: 'Merchants Group' },
  { key: 'Mercury', display: 'Mercury' },
  { key: 'MetLife', display: 'MetLife' },
  {
    key: 'Michigan Insurance Company',
    display: 'Michigan Insurance Company',
  },
  {
    key: 'Michigan Millers Mutual Insurance Company',
    display: 'Michigan Millers Mutual Insurance Company',
  },
  { key: 'Midwestern Indemnity', display: 'Midwestern Indemnity' },
  {
    key: 'MissionSelect Insurance Services',
    display: 'MissionSelect Insurance Services',
  },
  { key: 'MMG Insurance Company', display: 'MMG Insurance Company' },
  { key: 'Modern USA', display: 'Modern USA' },
  {
    key: 'Monarch National Ins Co',
    display: 'Monarch National Ins Co',
  },
  { key: 'Montgomery', display: 'Montgomery' },
  { key: 'Motorists Mutual', display: 'Motorists Mutual' },
  { key: 'MSA', display: 'MSA' },
  { key: 'Mutual Benefit', display: 'Mutual Benefit' },
  { key: 'Mutual of Enumclaw', display: 'Mutual of Enumclaw' },
  {
    key: 'National General One Choice',
    display: 'National General One Choice',
  },
  {
    key: 'National Lloyds Insurance Company',
    display: 'National Lloyds Insurance Company',
  },
  { key: 'Nationwide', display: 'Nationwide' },
  { key: 'Nationwide-Scottsdale', display: 'Nationwide-Scottsdale' },
  {
    key: 'New York Central Mutual',
    display: 'New York Central Mutual',
  },
  { key: 'NJ Skylands', display: 'NJ Skylands' },
  {
    key: 'NLC Insurance Companies',
    display: 'NLC Insurance Companies',
  },
  {
    key: 'Norfolk and Dedham Group',
    display: 'Norfolk and Dedham Group',
  },
  {
    key: 'Northern Neck Insurance Company',
    display: 'Northern Neck Insurance Company',
  },
  { key: 'Northstar', display: 'Northstar' },
  { key: 'Ohio Casualty', display: 'Ohio Casualty' },
  { key: 'Ohio Mutual', display: 'Ohio Mutual' },
  {
    key: 'Olympus Insurance Company',
    display: 'Olympus Insurance Company',
  },
  { key: 'Omaha P/C', display: 'Omaha P/C' },
  { key: 'One Beacon', display: 'One Beacon' },
  { key: 'Openly Inc', display: 'Openly Inc' },
  { key: 'Oregon Mutual', display: 'Oregon Mutual' },
  {
    key: 'Pacific Specialty Insurance Company',
    display: 'Pacific Specialty Insurance Company',
  },
  { key: 'Pacific Specialty', display: 'Pacific Specialty' },
  { key: 'Patriot Insurance', display: 'Patriot Insurance' },
  { key: 'Patrons Oxford', display: 'Patrons Oxford' },
  { key: 'Peerless', display: 'Peerless' },
  { key: 'Peerless/Montgomery', display: 'Peerless/Montgomery' },
  { key: 'Pekin', display: 'Pekin' },
  { key: 'PEMCO Insurance', display: 'PEMCO Insurance' },
  {
    key: 'Peninsula Insurance Companies',
    display: 'Peninsula Insurance Companies',
  },
  { key: 'Penn National', display: 'Penn National' },
  { key: 'Pioneer State Mutual', display: 'Pioneer State Mutual' },
  { key: 'Plymouth Rock', display: 'Plymouth Rock' },
  { key: 'Preferred Mutual', display: 'Preferred Mutual' },
  {
    key: 'Prepared Insurance Company',
    display: 'Prepared Insurance Company',
  },
  { key: 'Progressive', display: 'Progressive' },
  {
    key: 'Providence Mutual Fire Insurance Company',
    display: 'Providence Mutual Fire Insurance Company',
  },
  { key: 'Prudential', display: 'Prudential' },
  { key: 'QBE', display: 'QBE' },
  { key: 'Quincy Mutual', display: 'Quincy Mutual' },
  {
    key: 'RAM Mutual Insurance Company',
    display: 'RAM Mutual Insurance Company',
  },
  { key: 'Republic', display: 'Republic' },
  { key: 'Rockford Mutual', display: 'Rockford Mutual' },
  {
    key: 'Rockingham Casualty Company',
    display: 'Rockingham Casualty Company',
  },
  { key: 'Royal and Sun Alliance', display: 'Royal and Sun Alliance' },
  { key: 'Safeco', display: 'Safeco' },
  { key: 'Safepoint Insurance', display: 'Safepoint Insurance' },
  {
    key: 'Safety Insurance Company',
    display: 'Safety Insurance Company',
  },
  {
    key: 'SageSure Insurance Managers',
    display: 'SageSure Insurance Managers',
  },
  { key: 'SECURA', display: 'SECURA' },
  { key: 'Selective', display: 'Selective' },
  {
    key: 'Service Insurance Company',
    display: 'Service Insurance Company',
  },
  {
    key: 'Sheboygan Falls Insurance',
    display: 'Sheboygan Falls Insurance',
  },
  { key: 'Shelter Insurance', display: 'Shelter Insurance' },
  { key: 'Southern Fidelity P/C', display: 'Southern Fidelity P/C' },
  { key: 'Southern Fidelity', display: 'Southern Fidelity' },
  { key: 'Southern Mutual', display: 'Southern Mutual' },
  {
    key: 'Southern Oak Insurance Company',
    display: 'Southern Oak Insurance Company',
  },
  { key: 'Southern Trust', display: 'Southern Trust' },
  { key: 'St. Johns', display: 'St. Johns' },
  { key: 'St. Paul/Travelers', display: 'St. Paul/Travelers' },
  { key: 'Standard Mutual', display: 'Standard Mutual' },
  { key: 'State Auto', display: 'State Auto' },
  { key: 'State Farm', display: 'State Farm' },
  {
    key: 'Sterling Insurance Company',
    display: 'Sterling Insurance Company',
  },
  {
    key: 'Stillwater Property and Casualty',
    display: 'Stillwater Property and Casualty',
  },
  { key: 'Stonegate Insurance', display: 'Stonegate Insurance' },
  {
    key: 'Sublimity Insurance Company',
    display: 'Sublimity Insurance Company',
  },
  {
    key: 'The Philadelphia Contributionship XML',
    display: 'The Philadelphia Contributionship XML',
  },
  { key: 'Titan', display: 'Titan' },
  { key: 'Tower', display: 'Tower' },
  { key: 'Towerhill', display: 'Towerhill' },
  { key: 'Travelers', display: 'Travelers' },
  { key: 'TWFG', display: 'TWFG' },
  { key: 'TWICO', display: 'TWICO' },
  { key: 'Unigard', display: 'Unigard' },
  { key: 'Union Mutual', display: 'Union Mutual' },
  {
    key: 'United Fire and Casualty',
    display: 'United Fire and Casualty',
  },
  {
    key: 'United Heritage Property and Casualty Company',
    display: 'United Heritage Property and Casualty Company',
  },
  { key: 'United Home', display: 'United Home' },
  { key: 'United Insurance Group', display: 'United Insurance Group' },
  { key: 'Unitrin', display: 'Unitrin' },
  {
    key: 'Universal North America',
    display: 'Universal North America',
  },
  {
    key: 'Universal Property and Casualty Insurance Company',
    display: 'Universal Property and Casualty Insurance Company',
  },
  { key: 'Universal', display: 'Universal' },
  {
    key: 'Universal/Arrowhead Insurance company',
    display: 'Universal/Arrowhead Insurance company',
  },
  { key: 'UPCIC', display: 'UPCIC' },
  { key: 'USAA', display: 'USAA' },
  {
    key: 'Utica First Insurance Company',
    display: 'Utica First Insurance Company',
  },
  {
    key: 'Utica National Insurance',
    display: 'Utica National Insurance',
  },
  { key: 'Utica National', display: 'Utica National' },
  {
    key: 'Velocity Risk Underwriters LLC',
    display: 'Velocity Risk Underwriters LLC',
  },
  {
    key: 'Velocity Risk Underwriters Personal Lines',
    display: 'Velocity Risk Underwriters Personal Lines',
  },
  { key: 'Vermont Mutual', display: 'Vermont Mutual' },
  {
    key: 'Wayne Mutual Insurance Company',
    display: 'Wayne Mutual Insurance Company',
  },
  { key: 'Wellington Select', display: 'Wellington Select' },
  { key: 'Wellington Standard', display: 'Wellington Standard' },
  { key: 'West Bend', display: 'West Bend' },
  { key: 'Western National', display: 'Western National' },
  { key: 'Western Reserve Group', display: 'Western Reserve Group' },
  { key: 'Westfield', display: 'Westfield' },
  { key: 'Weston Specialty', display: 'Weston Specialty' },
  { key: 'White Mountains', display: 'White Mountains' },
  { key: 'Wilson Mutual', display: 'Wilson Mutual' },
  { key: 'Windsor', display: 'Windsor' },
  {
    key: 'Wisconsin Mutual Insurance Company',
    display: 'Wisconsin Mutual Insurance Company',
  },
  { key: 'Zurich', display: 'Zurich' },
];

export const AutoPriorCarrierEnum = [
  { key: 'Other Standard', display: 'Other Standard' },
  { key: 'Other Non-Standard', display: 'Other Non-Standard' },
  { key: 'No Prior Insurance', display: 'No Prior Insurance' },
  { key: '1st Auto', display: '1st Auto' },
  { key: '21st Century', display: '21st Century' },
  { key: 'A.Central', display: 'A.Central' },
  { key: 'AAA', display: 'AAA' },
  { key: 'AARP', display: 'AARP' },
  { key: 'Acadia', display: 'Acadia' },
  { key: 'Acceptance Insurance', display: 'Acceptance Insurance' },
  { key: 'Access General', display: 'Access General' },
  { key: 'Ace', display: 'Ace' },
  { key: 'Acuity', display: 'Acuity' },
  {
    key: 'Adirondack Ins Exchange',
    display: 'Adirondack Ins Exchange',
  },
  { key: 'Aegis', display: 'Aegis' },
  { key: 'Affirmative', display: 'Affirmative' },
  { key: 'Ag Workers Mutual Auto', display: 'Ag Workers Mutual Auto' },
  { key: 'AIC', display: 'AIC' },
  { key: 'AIG', display: 'AIG' },
  {
    key: 'Alert Auto Insurance Company',
    display: 'Alert Auto Insurance Company',
  },
  { key: 'Alfa Alliance', display: 'Alfa Alliance' },
  { key: 'Alinsco', display: 'Alinsco' },
  {
    key: 'Allied Trust Insurance Company',
    display: 'Allied Trust Insurance Company',
  },
  { key: 'Allied', display: 'Allied' },
  { key: 'Allstate', display: 'Allstate' },
  { key: 'America First', display: 'America First' },
  { key: 'American Commerce', display: 'American Commerce' },
  { key: 'American Family', display: 'American Family' },
  {
    key: 'American Freedom Insurance Company',
    display: 'American Freedom Insurance Company',
  },
  { key: 'American National', display: 'American National' },
  { key: 'Amerisure', display: 'Amerisure' },
  { key: 'Amica', display: 'Amica' },
  { key: 'AmShield', display: 'AmShield' },
  { key: 'AmWINS Star', display: 'AmWINS Star' },
  { key: 'Anchor General', display: 'Anchor General' },
  { key: 'Arrowhead Everest', display: 'Arrowhead Everest' },
  { key: 'Arrowhead', display: 'Arrowhead' },
  { key: 'ASI Lloyds', display: 'ASI Lloyds' },
  {
    key: 'ASI Select Auto Insurance Corp',
    display: 'ASI Select Auto Insurance Corp',
  },
  { key: 'Aspen', display: 'Aspen' },
  { key: 'Aspire', display: 'Aspire' },
  { key: 'AssuranceAmerica', display: 'AssuranceAmerica' },
  { key: 'Atlantic Mutual', display: 'Atlantic Mutual' },
  { key: 'Austin Mutual', display: 'Austin Mutual' },
  { key: 'Autoone', display: 'Autoone' },
  { key: 'Auto-Owners', display: 'Auto-Owners' },
  { key: 'AutoTex', display: 'AutoTex' },
  { key: 'Badger Mutual', display: 'Badger Mutual' },
  { key: 'Balboa', display: 'Balboa' },
  { key: 'Bankers', display: 'Bankers' },
  { key: 'Beacon National', display: 'Beacon National' },
  { key: 'Bear River Mutual', display: 'Bear River Mutual' },
  { key: 'Brethern Mutual', display: 'Brethern Mutual' },
  { key: 'Bristol West', display: 'Bristol West' },
  { key: 'Buckeye', display: 'Buckeye' },
  { key: 'California Casualty', display: 'California Casualty' },
  { key: 'Cameron Mutual', display: 'Cameron Mutual' },
  {
    key: 'Capital Insurance Group',
    display: 'Capital Insurance Group',
  },
  {
    key: 'Capitol Insurance Company',
    display: 'Capitol Insurance Company',
  },
  {
    key: 'Casualty Underwriters Insurance Company',
    display: 'Casualty Underwriters Insurance Company',
  },
  { key: 'Celina', display: 'Celina' },
  { key: 'Centennial', display: 'Centennial' },
  { key: 'Central Mutual of OH', display: 'Central Mutual of OH' },
  { key: 'Century National', display: 'Century National' },
  { key: 'Charter', display: 'Charter' },
  { key: 'Chubb', display: 'Chubb' },
  { key: 'Cincinnati Casualty', display: 'Cincinnati Casualty' },
  { key: 'Cincinnati Insurance', display: 'Cincinnati Insurance' },
  { key: 'Citizens', display: 'Citizens' },
  { key: 'Clearcover', display: 'Clearcover' },
  { key: 'CNA', display: 'CNA' },
  { key: 'Colonial Penn', display: 'Colonial Penn' },
  { key: 'Colorado Casualty', display: 'Colorado Casualty' },
  { key: 'Columbia', display: 'Columbia' },
  { key: 'Commerce West', display: 'Commerce West' },
  { key: 'Commonwealth', display: 'Commonwealth' },
  {
    key: 'Concord Group Insurance',
    display: 'Concord Group Insurance',
  },
  { key: 'Connect Banner', display: 'Connect Banner' },
  {
    key: 'Constitutional Casualty',
    display: 'Constitutional Casualty',
  },
  { key: 'Consumers', display: 'Consumers' },
  { key: 'Cornerstone', display: 'Cornerstone' },
  { key: 'Country Insurance', display: 'Country Insurance' },
  { key: 'Countryway Insurance', display: 'Countryway Insurance' },
  { key: 'Countrywide', display: 'Countrywide' },
  { key: 'Cover Insurance', display: 'Cover Insurance' },
  { key: 'CSE', display: 'CSE' },
  { key: 'Cumberland', display: 'Cumberland' },
  { key: 'Dairyland', display: 'Dairyland' },
  { key: 'Deerbrook', display: 'Deerbrook' },
  {
    key: 'Delta Lloyds Insurance Company',
    display: 'Delta Lloyds Insurance Company',
  },
  { key: 'Depositors', display: 'Depositors' },
  { key: 'Direct General', display: 'Direct General' },
  { key: 'Direct', display: 'Direct' },
  { key: 'Discovery', display: 'Discovery' },
  { key: 'Donegal', display: 'Donegal' },
  { key: 'Drive', display: 'Drive' },
  { key: 'Electric', display: 'Electric' },
  { key: 'Elephant Insurance', display: 'Elephant Insurance' },
  { key: 'Embark General', display: 'Embark General' },
  { key: 'EMC', display: 'EMC' },
  { key: 'Empower', display: 'Empower' },
  { key: 'Encompass', display: 'Encompass' },
  { key: 'Encova Exceed', display: 'Encova Exceed' },
  { key: 'Enumclaw Insurance', display: 'Enumclaw Insurance' },
  { key: 'Erie', display: 'Erie' },
  { key: 'Esurance', display: 'Esurance' },
  { key: 'Eveready', display: 'Eveready' },
  { key: 'Explorer', display: 'Explorer' },
  { key: 'Farm Bureau', display: 'Farm Bureau' },
  { key: 'Farmers', display: 'Farmers' },
  { key: 'Federated', display: 'Federated' },
  { key: 'Fidelity', display: 'Fidelity' },
  { key: 'Financial Indemnity', display: 'Financial Indemnity' },
  { key: 'Firemans Fund', display: 'Firemans Fund' },
  { key: 'First Acceptance', display: 'First Acceptance' },
  { key: 'First American', display: 'First American' },
  { key: 'First Auto', display: 'First Auto' },
  { key: 'First Chicago', display: 'First Chicago' },
  { key: 'First Connect', display: 'First Connect' },
  { key: 'Fitchburg Mutual', display: 'Fitchburg Mutual' },
  { key: 'Flagship Insurance', display: 'Flagship Insurance' },
  { key: 'Foremost', display: 'Foremost' },
  { key: 'Founders', display: 'Founders' },
  { key: 'Frankenmuth', display: 'Frankenmuth' },
  { key: 'Fred Loya', display: 'Fred Loya' },
  { key: 'Fremont Insurance', display: 'Fremont Insurance' },
  { key: 'GAINSCO Auto Insurance', display: 'GAINSCO Auto Insurance' },
  { key: 'Gateway', display: 'Gateway' },
  { key: 'Geico', display: 'Geico' },
  { key: 'General Casualty', display: 'General Casualty' },
  { key: 'Germania Insurance', display: 'Germania Insurance' },
  { key: 'Germantown Mutual', display: 'Germantown Mutual' },
  { key: 'GMAC', display: 'GMAC' },
  { key: 'Goodville Mutual', display: 'Goodville Mutual' },
  {
    key: 'Grange Insurance Association',
    display: 'Grange Insurance Association',
  },
  { key: 'Grange', display: 'Grange' },
  { key: 'GRE/Go America', display: 'GRE/Go America' },
  { key: 'Great American', display: 'Great American' },
  { key: 'Grinnell', display: 'Grinnell' },
  { key: 'Guide One', display: 'Guide One' },
  {
    key: 'Hallmark Insurance Company',
    display: 'Hallmark Insurance Company',
  },
  { key: 'Hanover', display: 'Hanover' },
  { key: 'Harbor', display: 'Harbor' },
  { key: 'Harleysville', display: 'Harleysville' },
  { key: 'Hartford OMNI', display: 'Hartford OMNI' },
  { key: 'Hartford', display: 'Hartford' },
  { key: 'Hastings Mutual', display: 'Hastings Mutual' },
  {
    key: 'Haulers Insurance Company',
    display: 'Haulers Insurance Company',
  },
  { key: 'Hawkeye Security', display: 'Hawkeye Security' },
  { key: 'HDI', display: 'HDI' },
  {
    key: 'Hochheim Prairie Insurance',
    display: 'Hochheim Prairie Insurance',
  },
  { key: 'Horace Mann', display: 'Horace Mann' },
  { key: 'Houston General', display: 'Houston General' },
  { key: 'IFA', display: 'IFA' },
  { key: 'Imperial Casualty', display: 'Imperial Casualty' },
  { key: 'IMT Ins', display: 'IMT Ins' },
  { key: 'Indiana Farmers', display: 'Indiana Farmers' },
  { key: 'Indiana', display: 'Indiana' },
  { key: 'Infinity', display: 'Infinity' },
  { key: 'Insuremax', display: 'Insuremax' },
  { key: 'Insurequest', display: 'Insurequest' },
  { key: 'Integon', display: 'Integon' },
  { key: 'Integrity', display: 'Integrity' },
  {
    key: 'Iowa Mutual Insurance Company',
    display: 'Iowa Mutual Insurance Company',
  },
  { key: 'Kemper Specialty', display: 'Kemper Specialty' },
  { key: 'Kemper', display: 'Kemper' },
  { key: 'Kingsway', display: 'Kingsway' },
  {
    key: 'Legacy - Arizona Auto Ins. Co',
    display: 'Legacy - Arizona Auto Ins. Co',
  },
  { key: 'LeMars Insurance', display: 'LeMars Insurance' },
  { key: 'Liberty Mutual', display: 'Liberty Mutual' },
  { key: 'Liberty Northwest', display: 'Liberty Northwest' },
  {
    key: 'Madison Mutual Insurance Company',
    display: 'Madison Mutual Insurance Company',
  },
  { key: 'Maidstone Insurance', display: 'Maidstone Insurance' },
  { key: 'MAIF', display: 'MAIF' },
  { key: 'Main Street America', display: 'Main Street America' },
  { key: 'Mapfre', display: 'Mapfre' },
  { key: 'Markel', display: 'Markel' },
  {
    key: 'Maryland Auto Insurance',
    display: 'Maryland Auto Insurance',
  },
  { key: 'Mendakota', display: 'Mendakota' },
  { key: 'Mendota', display: 'Mendota' },
  { key: 'Merchants Group', display: 'Merchants Group' },
  { key: 'Mercury', display: 'Mercury' },
  { key: 'MetLife', display: 'MetLife' },
  { key: 'Metropolitan', display: 'Metropolitan' },
  {
    key: 'Michigan Insurance Company',
    display: 'Michigan Insurance Company',
  },
  {
    key: 'Michigan Millers Mutual Insurance Company',
    display: 'Michigan Millers Mutual Insurance Company',
  },
  { key: 'Mid-Continent', display: 'Mid-Continent' },
  { key: 'Midwestern Indemnity', display: 'Midwestern Indemnity' },
  { key: 'Mile Auto', display: 'Mile Auto' },
  { key: 'MMG Insurance Company', display: 'MMG Insurance Company' },
  { key: 'Montgomery', display: 'Montgomery' },
  {
    key: 'Motor Club Insurance Company',
    display: 'Motor Club Insurance Company',
  },
  { key: 'Motorists Mutual', display: 'Motorists Mutual' },
  { key: 'MSA', display: 'MSA' },
  { key: 'Mt. Washington', display: 'Mt. Washington' },
  { key: 'Mutual Benefit', display: 'Mutual Benefit' },
  { key: 'Mutual of Enumclaw', display: 'Mutual of Enumclaw' },
  { key: 'National General', display: 'National General' },
  {
    key: 'National Lloyds Insurance Company',
    display: 'National Lloyds Insurance Company',
  },
  { key: 'Nationwide', display: 'Nationwide' },
  {
    key: 'New York Central Mutual',
    display: 'New York Central Mutual',
  },
  { key: 'NJ Manufacturers', display: 'NJ Manufacturers' },
  { key: 'NJ Skylands', display: 'NJ Skylands' },
  {
    key: 'NLC Insurance Companies',
    display: 'NLC Insurance Companies',
  },
  { key: 'Nodak Mutual', display: 'Nodak Mutual' },
  {
    key: 'Northern Neck Insurance Company',
    display: 'Northern Neck Insurance Company',
  },
  { key: 'Northstar', display: 'Northstar' },
  { key: 'NYAIP', display: 'NYAIP' },
  { key: 'NYCM Standard', display: 'NYCM Standard' },
  { key: 'Occidental', display: 'Occidental' },
  { key: 'Ocean Harbor', display: 'Ocean Harbor' },
  { key: 'Ohio Casualty', display: 'Ohio Casualty' },
  { key: 'Ohio Mutual', display: 'Ohio Mutual' },
  { key: 'Omaha P/C', display: 'Omaha P/C' },
  { key: 'Omni Insurance Co', display: 'Omni Insurance Co' },
  { key: 'One Beacon', display: 'One Beacon' },
  { key: 'Oregon Mutual', display: 'Oregon Mutual' },
  { key: 'Palisades', display: 'Palisades' },
  {
    key: 'Partners Mutual Insurance',
    display: 'Partners Mutual Insurance',
  },
  { key: 'Patriot', display: 'Patriot' },
  { key: 'Patrons Oxford', display: 'Patrons Oxford' },
  { key: 'Peerless/Montgomery', display: 'Peerless/Montgomery' },
  { key: 'Pekin', display: 'Pekin' },
  { key: 'Pemco', display: 'Pemco' },
  {
    key: 'Peninsula Insurance Companies',
    display: 'Peninsula Insurance Companies',
  },
  { key: 'Penn National', display: 'Penn National' },
  {
    key: 'Personal Service Insurance',
    display: 'Personal Service Insurance',
  },
  { key: 'Phoenix Indemnity', display: 'Phoenix Indemnity' },
  { key: 'Pioneer State Mutual', display: 'Pioneer State Mutual' },
  { key: 'Plymouth Rock', display: 'Plymouth Rock' },
  { key: 'Preferred Mutual', display: 'Preferred Mutual' },
  { key: 'Proformance', display: 'Proformance' },
  { key: 'Progressive', display: 'Progressive' },
  {
    key: 'Providence Mutual Fire Insurance Company',
    display: 'Providence Mutual Fire Insurance Company',
  },
  { key: 'Prudential', display: 'Prudential' },
  { key: 'QBE', display: 'QBE' },
  { key: 'Quincy Mutual', display: 'Quincy Mutual' },
  {
    key: 'RAM Mutual Insurance Company',
    display: 'RAM Mutual Insurance Company',
  },
  { key: 'Republic', display: 'Republic' },
  { key: 'Response', display: 'Response' },
  { key: 'Rockford Mutual', display: 'Rockford Mutual' },
  {
    key: 'Rockingham Casualty Company',
    display: 'Rockingham Casualty Company',
  },
  { key: 'Royal and Sun Alliance', display: 'Royal and Sun Alliance' },
  { key: 'Safe Auto', display: 'Safe Auto' },
  { key: 'Safeco', display: 'Safeco' },
  {
    key: 'Safety Insurance Company',
    display: 'Safety Insurance Company',
  },
  { key: 'Safeway', display: 'Safeway' },
  { key: 'Sagamore', display: 'Sagamore' },
  { key: 'SECURA', display: 'SECURA' },
  { key: 'Selective', display: 'Selective' },
  { key: 'Sentry Ins', display: 'Sentry Ins' },
  {
    key: 'Sheboygan Falls Insurance',
    display: 'Sheboygan Falls Insurance',
  },
  { key: 'Shelter Insurance', display: 'Shelter Insurance' },
  { key: 'Southern County', display: 'Southern County' },
  { key: 'Southern Mutual', display: 'Southern Mutual' },
  { key: 'Southern Trust', display: 'Southern Trust' },
  { key: 'St. Paul/Travelers', display: 'St. Paul/Travelers' },
  { key: 'Standard Mutual', display: 'Standard Mutual' },
  { key: 'Star Casualty', display: 'Star Casualty' },
  { key: 'State Auto', display: 'State Auto' },
  { key: 'State Farm', display: 'State Farm' },
  { key: 'StillWater', display: 'StillWater' },
  { key: 'Stonegate', display: 'Stonegate' },
  {
    key: 'Sublimity Insurance Company',
    display: 'Sublimity Insurance Company',
  },
  { key: 'Sun Coast Platinum', display: 'Sun Coast Platinum' },
  { key: 'Texas Ranger MGA', display: 'Texas Ranger MGA' },
  { key: 'The General', display: 'The General' },
  { key: 'Titan', display: 'Titan' },
  { key: 'Topa', display: 'Topa' },
  { key: 'Tower', display: 'Tower' },
  { key: 'Travelers', display: 'Travelers' },
  { key: 'TWFG', display: 'TWFG' },
  { key: 'Unigard', display: 'Unigard' },
  { key: 'United Automobile', display: 'United Automobile' },
  {
    key: 'United Fire and Casualty',
    display: 'United Fire and Casualty',
  },
  {
    key: 'United Heritage Property and Casualty Company',
    display: 'United Heritage Property and Casualty Company',
  },
  { key: 'United Home', display: 'United Home' },
  { key: 'Unitrin', display: 'Unitrin' },
  { key: 'Universal', display: 'Universal' },
  { key: 'USAA', display: 'USAA' },
  { key: 'Utica National', display: 'Utica National' },
  { key: 'Victoria', display: 'Victoria' },
  {
    key: 'Wadena Insurance Company',
    display: 'Wadena Insurance Company',
  },
  {
    key: 'Wayne Mutual Insurance Company',
    display: 'Wayne Mutual Insurance Company',
  },
  { key: 'West Bend', display: 'West Bend' },
  { key: 'Western National', display: 'Western National' },
  { key: 'Western Reserve Group', display: 'Western Reserve Group' },
  { key: 'Westfield', display: 'Westfield' },
  { key: 'White Mountains', display: 'White Mountains' },
  { key: 'Wilshire', display: 'Wilshire' },
  { key: 'Wilson Mutual', display: 'Wilson Mutual' },
  { key: 'Wind Haven', display: 'Wind Haven' },
  { key: 'Windsor', display: 'Windsor' },
  { key: 'Wisconsin Mutual', display: 'Wisconsin Mutual' },
  {
    key: 'Wolverine Mutual Insurance Company',
    display: 'Wolverine Mutual Insurance Company',
  },
  {
    key: 'Workmens Auto Insurance Company',
    display: 'Workmens Auto Insurance Company',
  },
  {
    key: 'Worth Casualty Insurance Company',
    display: 'Worth Casualty Insurance Company',
  },
  { key: 'Zurich', display: 'Zurich' },
];
