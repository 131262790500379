import { useState, useEffect, useCallback } from 'react';
import { IntakeNavOptions } from '../../components/common/constants';
import SideNav from '../../components/SideNav';
import Switch from '../../components/Switch';
import { NavBarItem } from '../../components/SideNavItem';
import type { AgentIntakeForm } from '../../services/forms/agent/schema';
import { agentIntake } from '../../services/forms/agent';
import { FormPage } from '../../components/forms';
import { useForm, FormProvider } from 'react-hook-form';
import { defaultValuesMap } from '../../services/forms/agent/schema';
import type { DefaultValuesMap } from '../../services/forms/agent/schema';
import { useWindowConfirm } from '../../components/utility/useWindowConfirm';
import { useData } from '@/pages/opportunity/components/utility/useData';
import { flattenToEAV } from './services/utils';
import { postEav } from './services/network/eav';
import AlertToast, {
  AlertToast as AlertToastInterface,
} from '@/components/shared/alertToast';
import { useAuthenticatedFetch } from '../../components/common/useAuthenticatedFetch';
import { formSearch } from './services/utils/formSearch';
import { SendToRaterDialog } from '@/components/forms/SendToRaterDialog';
import { useRaterIntegration } from './components/utility/useRaterIntegration';
import { useQuoteSubmissionForApplication } from './components/utility/useQuoteSubmissionForApp';
import { RaterProductEnabled } from '@/services/forms/models/rateProductTypes';
import { useSendAndGetQuoteGroups } from './components/utility/useSendAndGetQuoteGroups';
import {
  getSuccessMessage,
  getErrorMessage,
  getRaterButtonErrorMessage,
  getErrorMessages,
} from '@/services/forms/helpers/utils';
import { RatersEnum } from '@bwinsurance/meta-rater-types';

const OpportunityNew = () => {
  const authedFetch = useAuthenticatedFetch();
  const { formData, loading, errorText, eavEntityId } = useData();
  const { raterProductEnabled } = useRaterIntegration({
    loading,
    crmAgentId: formData?.crm?.agentId,
  });
  const { successQuoteGroups, setSuccessQuoteGroups, successQRQuoteGroup } =
    useQuoteSubmissionForApplication({
      loading,
      applicationId: formData?.application?.id,
    });

  const [selectedTab, setSelectedTab] = useState<NavBarItem>({
    icon: <span className="material-symbols-outlined">people_outline</span>,
    value: IntakeNavOptions.APPLICANTS,
    label: 'Applicant(s)',
    search_count: null,
  });
  const [alertToasts, setAlertToasts] = useState<AlertToastInterface[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [raterDialogOpen, setRaterDialogOpen] = useState(false);
  const [sendingToRater, setSendingToRater] = useState(false);
  const [lastSaved, setLastSaved] = useState<string | null>(null);

  type Field = {
    display: string;
    page: string;
  };
  const [searchResults, setSearchResults] = useState<Field[] | null>(null);

  const formMethods = useForm<AgentIntakeForm>({
    defaultValues: agentIntake.defaults,
    mode: 'onBlur',
  });

  const { isDirty, isValid, errors } = formMethods.formState;

  useEffect(() => {
    if (formData) {
      formMethods.reset(formData);
    }
  }, [formData, formMethods]);

  const onSubmit = (data: AgentIntakeForm) => {
    console.log(data);
  };

  const handleCloseAlert = (e: any, index: number) => {
    if (!e) {
      setAlertToasts([]);
    } else if (e.type === 'click') {
      const tempToasts = [...alertToasts];
      tempToasts.splice(index, 1);
      setAlertToasts(tempToasts);
    }
  };

  const getLastSaved = () => {
    const now = new Date();
    const formattedDate = now.toLocaleString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });

    return formattedDate;
  };

  const onSave = useCallback(async () => {
    let saveError = false;
    let lastSaved = null;

    if (!isDirty && isValid) {
      setAlertToasts([{ type: 'success', text: 'Data saved' }]);
      lastSaved = getLastSaved();
      saveError = false;
      return { saveError, lastSaved };
    }

    if (!isValid || Object.keys(errors).length) {
      setAlertToasts([
        { type: 'warning', text: getErrorMessage('InvalidFields') },
      ]);
      saveError = true;
      return { saveError, lastSaved };
    }

    if (eavEntityId) {
      setIsSaving(true);

      try {
        const data = formMethods.getValues();
        const eav = flattenToEAV(data, eavEntityId);

        const result = await postEav({ body: eav, fetchFn: authedFetch });

        if (!result) {
          saveError = true;
          throw new Error('Failed to save EAV');
        }

        setAlertToasts([{ type: 'success', text: 'Data saved' }]);
        formMethods.reset(formMethods.getValues(), { keepValues: true });
        saveError = false;
        lastSaved = getLastSaved();
      } catch (err) {
        console.log(err);
        setAlertToasts([
          {
            type: 'warning',
            text: 'Something went wrong. Please try again!',
          },
        ]);
        saveError = true;
      }

      setIsSaving(false);
    }

    return { saveError, lastSaved };
  }, [isDirty, isValid, eavEntityId, formMethods, authedFetch, setAlertToasts]);

  useWindowConfirm(isDirty, onSave);

  useEffect(() => {
    const autoSave = async () => {
      if (isDirty) {
        try {
          const { lastSaved } = await onSave();
          setLastSaved(lastSaved);
        } catch (error) {
          console.error('Auto-save failed:', error);
        }
      }
    };

    const autoSaveInterval = setInterval(() => {
      autoSave();
    }, 60000); // 1 minute

    return () => clearInterval(autoSaveInterval);
  }, [isDirty, onSave]);

  useWindowConfirm(isDirty, onSave);

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const results = value ? formSearch(value, agentIntake) : null;
    setSearchValue(value);
    setSearchResults(results);
  };

  const { sendAndGetQuoteGroups } = useSendAndGetQuoteGroups();

  const handleSendRater = async (raterProductToSend: RaterProductEnabled[]) => {
    const isSendingQuoteRushOnly =
      raterProductToSend.length === 1 &&
      raterProductToSend[0].rater === RatersEnum.enum.QuoteRush;
    if (successQRQuoteGroup && isSendingQuoteRushOnly) {
      const leadId = successQRQuoteGroup.raterData?.leadId;
      setAlertToasts([
        {
          type: 'warning',
          text: getErrorMessage('AlreadySentError', {
            leadId: leadId,
            quoteGroupNames: ['QuoteRUSH'],
          }),
        },
      ]);
      toggleRaterDialog();
      return;
    }

    try {
      setSendingToRater(true);
      const { saveError } = await onSave();
      if (!saveError) {
        const toasts = [];
        if (!eavEntityId) {
          throw new Error('Missing entity id, unable to send to rater');
        }
        const { completedQuoteGroups, failedQuoteGroups } =
          await sendAndGetQuoteGroups(eavEntityId, raterProductToSend);

        if (completedQuoteGroups.length) {
          setSuccessQuoteGroups([
            ...completedQuoteGroups,
            ...(successQRQuoteGroup ? [successQRQuoteGroup] : []),
          ]);
          toasts.push({
            type: 'success',
            text: getSuccessMessage(completedQuoteGroups),
          });
        }

        if (failedQuoteGroups.length) {
          const messages = getErrorMessages(failedQuoteGroups);
          messages.forEach((message) => {
            toasts.push({
              type: 'warning',
              text: message,
            });
          });
        }
        setAlertToasts(toasts);
      }
    } catch (error) {
      console.log(error);
      setAlertToasts([
        {
          type: 'warning',
          text: getErrorMessage(),
        },
      ]);
    }
    setSendingToRater(false);
    toggleRaterDialog();
  };

  const toggleRaterDialog = () => {
    const hasEnabledProducts = raterProductEnabled.some((item) => item.enabled);
    if (!hasEnabledProducts) {
      setAlertToasts([
        {
          type: 'warning',
          text: getRaterButtonErrorMessage(),
        },
      ]);
      return;
    }
    setRaterDialogOpen(!raterDialogOpen);
  };

  if (loading) {
    return (
      <div className="text-center text-xl mt-10">
        <span>Loading</span>
      </div>
    );
  }
  if (errorText) {
    return (
      <div className="text-center text-xl mt-10">
        <span>{errorText}</span>
      </div>
    );
  }

  const alertStyleMap = {
    0: '',
    1: 'bottom-24',
    2: 'bottom-44',
  };

  return (
    <div className="border border-[#E9E9E9] gap-5 h-full">
      {sendingToRater && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/70 gap-2">
          <span className="material-symbols-outlined text-[20px] animate-spin text-light-text-inverse">
            progress_activity
          </span>
          <span className="text-light-text-inverse text-lg font-semibold">
            Sending...
          </span>
        </div>
      )}
      {alertToasts.map((toast, index) => {
        const additionalClass =
          alertStyleMap[index as keyof typeof alertStyleMap];

        return (
          <AlertToast
            open={!!alertToasts.length}
            closeAlert={(e: any) => handleCloseAlert(e, index)}
            type={toast.type}
            text={toast.text}
            additionalClass={additionalClass}
          />
        );
      })}
      <SendToRaterDialog
        open={raterDialogOpen}
        handleSendRater={handleSendRater}
        toggleRaterDialog={toggleRaterDialog}
        raterProductEnabled={raterProductEnabled}
        successQRQuoteGroup={successQRQuoteGroup}
      />
      <FormProvider {...formMethods}>
        <SideNav
          selected={selectedTab}
          setSelected={setSelectedTab}
          onSave={onSave}
          onSearch={onSearch}
          isSaving={isSaving}
          searchResults={searchResults}
          setSearchResults={setSearchResults}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          lastSaved={lastSaved}
          setLastSaved={setLastSaved}
          toggleRaterDialog={toggleRaterDialog}
          raterProductEnabled={raterProductEnabled}
        />
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <div className="flex flex-col ml-52 p-6 gap-8">
            <Switch selectedKey={selectedTab.parent || selectedTab.value}>
              <FormPage
                key={IntakeNavOptions.APPLICANTS}
                page={agentIntake.pages.applicants}
                defaultValuesMap={defaultValuesMap as DefaultValuesMap}
                searchResults={searchResults}
              />
              <FormPage
                key={IntakeNavOptions.APPLICANT}
                page={agentIntake.pages.applicant}
                defaultValuesMap={defaultValuesMap as DefaultValuesMap}
                searchResults={searchResults}
              />
              <FormPage
                key={IntakeNavOptions.COAPPLICANT}
                page={agentIntake.pages.coApplicant}
                defaultValuesMap={defaultValuesMap as DefaultValuesMap}
                searchResults={searchResults}
              />
              <FormPage
                key={IntakeNavOptions.AUTO}
                page={agentIntake.pages.auto}
                defaultValuesMap={defaultValuesMap as DefaultValuesMap}
                searchResults={searchResults}
                quoteGroups={successQuoteGroups}
              />
              <FormPage
                key={IntakeNavOptions.HOME}
                page={agentIntake.pages.property}
                defaultValuesMap={defaultValuesMap as DefaultValuesMap}
                searchResults={searchResults}
                quoteGroups={successQuoteGroups}
              />
            </Switch>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default OpportunityNew;
