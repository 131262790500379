import AlertToast, {
  AlertToast as AlertToastInterface,
} from '../../../../components/shared/alertToast';
import { Form } from '../../models/form';
import ControlBar from './../controlBar';
import FormList from '../formList/FormList';
import Button from '../../../../components/shared/button';
import Email from './../dialogs/EmailController';
import { PolicyKeySet } from './types';
import { useFormContext } from '../form/FormContext';
import styles from './FormView.module.css';

interface Props {
  alertToastOpen: boolean;
  dialogOpen: boolean;
  setDialogOpen(isOpen: boolean): void;
  alertToast: AlertToastInterface | undefined;
  closeAlertToast(): void;
  showAlertToast(type: string, text: string): void;
  username: string | undefined;
}

const FormView: React.FC<Props> = ({
  alertToastOpen,
  setDialogOpen,
  showAlertToast,
  dialogOpen,
  alertToast,
  closeAlertToast,
  username,
}) => {
  const {
    selectedPolicyKeys,
    selectedFormListKeys,
    forms,
    associatedContactList,
    fetchedData,
  } = useFormContext();

  const formCount = forms.length;

  const hasSelectedItems =
    selectedFormListKeys.length !== 0 || selectedPolicyKeys.length !== 0;
  const hasMultipleSelectedItems =
    selectedFormListKeys.length + selectedPolicyKeys.length > 1;

  const selectedForms: Form[] = forms.filter((form) =>
    selectedFormListKeys.includes(form.key)
  );

  const selectedPolicyKeyListWithFormNames: PolicyKeySet[] =
    selectedPolicyKeys.map((policyKeyItem) => {
      const correspondingForm = forms.find(
        (form) => form.key === policyKeyItem.formKey
      );
      if (correspondingForm) {
        let formName = correspondingForm.name;
        if (correspondingForm.policies && correspondingForm.policies.length) {
          const correspondingPolicy = correspondingForm?.policies.find(
            (policy) => policy.amsPolicyId === policyKeyItem.policyKey
          );
          if (correspondingPolicy) {
            formName = `${formName} |  ${correspondingPolicy?.carrier.name} | ${correspondingPolicy?.policyNumber}`;
          }
        }
        policyKeyItem.formName = formName;
      }
      return policyKeyItem;
    });

  return (
    <div className={styles['form-view-container']}>
      {/* <ControlBar formCount={formCount} /> */}
      {fetchedData.state !== 'complete' && <h4>Loading...</h4>}
      <FormList />
      <div>
        <Button
          type={'primary'}
          callback={() => setDialogOpen(true)}
          disabled={!hasSelectedItems}
          theme={'crm'}
        >
          Send
          {hasSelectedItems &&
            ` ${selectedFormListKeys.length + selectedPolicyKeys.length} Form${
              hasMultipleSelectedItems ? 's' : ''
            }`}
        </Button>
      </div>
      {alertToast && (
        <AlertToast
          open={alertToastOpen}
          closeAlert={closeAlertToast}
          type={alertToast.type}
          text={alertToast.text}
        />
      )}
      <Email
        selectedFormList={selectedForms}
        selectedPolicyKeys={selectedPolicyKeyListWithFormNames}
        associatedContactList={associatedContactList}
        open={dialogOpen}
        closeDialog={() => setDialogOpen(false)}
        showAlertToast={showAlertToast}
        username={username}
      />
    </div>
  );
};

export default FormView;
