import { useEffect, useState } from 'react';
import Button from '../../../../components/shared/button';
import Dialog from '../../../../components/shared/dialog';
import { ListOption, Select } from '../../../../components/shared/select';
import Icon from '../../../../components/Icon';
import { Policy } from '../../models/policy';
import { useAuthenticatedFetch } from '../../../../components/common/useAuthenticatedFetch';
import { documentConfig } from '../../../../components/common/config';
import { PolicyJobStatus } from '../../models/policyJobStatus';
import {
  autoPullDocument,
  getJobStatus,
} from '../../services/jobOrchestrationService';
import styles from './autoPull.module.css';
import '../../../../theme.module.css';

const botActionStatus = {
  COMPLETED: 'Completed',
  FAILED: 'Failed',
};

export default function AutoPull({
  open,
  closeDialog,
  showAutoPullMessage,
  policyList,
  refreshData,
}: {
  open: boolean;
  closeDialog: any;
  showAutoPullMessage: any;
  policyList: Policy[];
  refreshData: () => void;
}) {
  const [policyId, setPolicyId] = useState<string>('');
  const [policyOptionList, setPolicyOptionList] = useState<ListOption[]>([]);
  const [botAction, setBotAction] = useState<string>('');
  const [botActionOptionList, setBotActionOptionList] = useState<ListOption[]>(
    []
  );
  const authedFetch = useAuthenticatedFetch();

  useEffect(() => {
    const mappedValues = policyList.map(
      (policy: Policy) =>
        ({
          label: `${policy.carrier.name} (${policy.policyNumber})`,
          value: policy.policyId,
          groupByValue: new Date(policy.effectiveDate).getFullYear(),
          disabled: !policy.jobs?.length,
        } as ListOption)
    );

    if (policyList.length === 1) {
      mappedValues[0].selected = true;
      setPolicyId(mappedValues[0].value);
      updateBotActionOptions(policyList[0]);
    }
    setPolicyOptionList(mappedValues);
  }, [policyList]);

  const handleGetLatest = () => {
    resetThenClose();

    autoPullDocument(policyList, policyId, botAction, authedFetch)
      .then((result: any) => {
        // poll for botAction status, refresh once complete
        const pollInterval = setInterval(() => {
          getJobStatus(result.id, authedFetch).then(
            (result: PolicyJobStatus) => {
              if (result.status === botActionStatus.COMPLETED) {
                clearInterval(pollInterval);
                showAutoPullMessage('none');
                refreshData();
              } else if (result.status === botActionStatus.FAILED) {
                clearInterval(pollInterval);
                showAutoPullMessage('warning');
              }
            }
          );
        }, parseInt(documentConfig.autoPullPollInterval));
      })
      .catch((error: any) => {
        showAutoPullMessage('warning');
      });

    showAutoPullMessage('info');
  };

  const updateBotActionOptions = (selectedPolicy: Policy) => {
    if (selectedPolicy.jobs) {
      const botActionOptionList = selectedPolicy.jobs.map(
        (botAction: any) =>
          ({
            label: `${botAction.jobInfo?.name}`,
            value: botAction.id,
          } as ListOption)
      );
      if (selectedPolicy.jobs.length === 1) {
        botActionOptionList[0].selected = true;
        setBotAction(botActionOptionList[0].value);
      }
      setBotActionOptionList(botActionOptionList);
    }
  };

  const handlePolicySelect = (clickedPolicy: ListOption) => {
    setPolicyId(clickedPolicy.value);

    const policyOptionListCopy = [...policyOptionList];
    policyOptionListCopy.forEach((policy: ListOption) => {
      if (policy.value === clickedPolicy.value) {
        policy.selected = true;
        // change botAction options
        const selectedPolicy = policyList.filter(
          (policy: Policy) => policy.policyId === clickedPolicy.value
        )[0];
        updateBotActionOptions(selectedPolicy);
      } else {
        policy.selected = false;
      }
    });
    setPolicyOptionList(policyOptionListCopy);
  };

  const handleBotActionSelect = (clickedBotAction: ListOption) => {
    setBotAction(clickedBotAction.value);

    const botActionOptionListCopy = [...botActionOptionList];
    botActionOptionListCopy.forEach((botAction) => {
      if (botAction.label === clickedBotAction.label) {
        botAction.selected = true;
      } else {
        botAction.selected = false;
      }
    });

    setBotActionOptionList(botActionOptionListCopy);
  };

  const resetThenClose = () => {
    setPolicyId('');
    setPolicyOptionList((prevState: ListOption[]) => {
      const optionListCopy = [...prevState];
      optionListCopy.forEach((option: ListOption) => {
        option.selected = false;
      });
      return optionListCopy;
    });

    setBotAction('');
    setBotActionOptionList((prevState: ListOption[]) => {
      const optionListCopy = [...prevState];
      optionListCopy.forEach((option: ListOption) => {
        option.selected = false;
      });
      return optionListCopy;
    });

    closeDialog();
  };

  return (
    <Dialog open={open} theme={'crm'}>
      <div className={styles.header}>
        <h5>What are you looking for?</h5>
        <div className={styles.closeIcon} onClick={resetThenClose}>
          <Icon type={'dismiss'} color={'black'} size={20} />
        </div>
      </div>
      <div className={styles.body}>
        <Select
          label={'Policy #'}
          theme={'crm'}
          options={policyOptionList}
          placeholder={'Select a policy...'}
          updateValue={handlePolicySelect}
        />
        <Select
          label={'Bot Action'}
          theme={'crm'}
          options={botActionOptionList}
          placeholder={'Select a botAction...'}
          updateValue={handleBotActionSelect}
        />
      </div>
      <div className={styles.buttonContainer}>
        <Button
          callback={handleGetLatest}
          type={'primary'}
          theme={'crm'}
          disabled={!policyId || !botAction}
        >
          Get latest
        </Button>
        <Button callback={resetThenClose} type={'secondary'} theme={'crm'}>
          Cancel
        </Button>
      </div>
    </Dialog>
  );
}
