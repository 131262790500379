import { useState, type FC, useEffect } from 'react';
import {
  RaterProductEnabled,
  RaterSelectedMap,
} from '@/services/forms/models/rateProductTypes';
import { isRaterProductEnabled } from '@/services/forms/helpers/utils';
import { QuoteGroupWithQuote } from '@/services/forms/models/quoteTypes';
import Dialog from '@/components/shared/dialog';
import { Button } from '@/components/ui/button';
import {
  Raters,
  InsuranceProductEnum,
  RatersEnum,
} from '@bwinsurance/meta-rater-types';
import Checkbox from '@/components/shared/checkbox';

export type SendToRaterDialogProps = {
  open: boolean;
  handleSendRater: (raterProductToSend: RaterProductEnabled[]) => void;
  toggleRaterDialog: () => void;
  raterProductEnabled: RaterProductEnabled[];
  successQRQuoteGroup?: QuoteGroupWithQuote;
};

const SendToRaterDialog: FC<SendToRaterDialogProps> = ({
  open,
  handleSendRater,
  toggleRaterDialog,
  raterProductEnabled,
  successQRQuoteGroup,
}) => {
  const getIsRaterSelected = (rater: Raters) => {
    let selected = true;

    if (rater === RatersEnum.enum.QuoteRush) {
      selected = !successQRQuoteGroup;
    }

    const matchingRaters = raterProductEnabled.filter(
      (raterProduct) => rater === raterProduct.rater
    );

    if (
      matchingRaters.length > 0 &&
      matchingRaters.every((raterProduct) => !raterProduct.enabled)
    ) {
      selected = false;
    }

    return selected;
  };

  useEffect(() => {
    if (raterProductEnabled) {
      setSelectRaters({
        QuoteRush: getIsRaterSelected(RatersEnum.enum.QuoteRush),
        EZLynx: getIsRaterSelected(RatersEnum.enum.EZLynx),
      });
    }
  }, [raterProductEnabled]);

  const [selectedRaters, setSelectRaters] = useState<RaterSelectedMap>({
    QuoteRush: false,
    EZLynx: false,
  });
  const enabledQuoteRushHome = isRaterProductEnabled(
    raterProductEnabled,
    RatersEnum.enum.QuoteRush,
    InsuranceProductEnum.enum.Home
  );
  const enabledEZLynxHome = isRaterProductEnabled(
    raterProductEnabled,
    RatersEnum.enum.EZLynx,
    InsuranceProductEnum.enum.Home
  );
  const enabledEZLynxAuto = isRaterProductEnabled(
    raterProductEnabled,
    RatersEnum.enum.EZLynx,
    InsuranceProductEnum.enum.Auto
  );
  const noneEnabled =
    !enabledQuoteRushHome && !enabledEZLynxHome && !enabledEZLynxAuto;
  if (!open || noneEnabled) {
    return null;
  }

  const noneSelected =
    !selectedRaters?.EZLynx &&
    (!selectedRaters?.QuoteRush || !!successQRQuoteGroup);

  const toggleSelectRater = (rater: Raters) => {
    if (successQRQuoteGroup && rater === RatersEnum.enum.QuoteRush) {
      return;
    }
    setSelectRaters({
      ...selectedRaters,
      [rater]: !selectedRaters[rater],
    });
  };

  const onClickSend = () => {
    const raters = Object.keys(selectedRaters) as Raters[];
    const ratersChecked = raters.filter(
      (rater) => selectedRaters[rater] === true
    );

    const ratersToSend: RaterProductEnabled[] = [];

    raterProductEnabled.forEach((raterProduct) => {
      if (!ratersChecked.includes(raterProduct.rater) || !raterProduct.enabled)
        return;

      const isQuoteRushWithoutSuccess =
        raterProduct.rater === RatersEnum.enum.QuoteRush &&
        !successQRQuoteGroup;
      const isEZLynx = raterProduct.rater === RatersEnum.enum.EZLynx;

      if (isQuoteRushWithoutSuccess || isEZLynx) {
        ratersToSend.push(raterProduct);
      }
    });

    handleSendRater(ratersToSend);
  };

  const hasQuoteRush = raterProductEnabled.some(
    (raterProduct) =>
      raterProduct.rater === RatersEnum.enum.QuoteRush &&
      raterProduct.enabled === true
  );

  const consolidatedRaters = raterProductEnabled.reduce<RaterProductEnabled[]>(
    (acc, curr) => {
      if (!curr.enabled) return acc;

      const existingRater = acc.find((r) => r.rater === curr.rater);
      const convertedProduct = curr.product;

      if (existingRater) {
        if (!existingRater.product.includes(convertedProduct)) {
          existingRater.product += `,${convertedProduct}`;
        }
      } else {
        acc.push({
          rater: curr.rater,
          product: convertedProduct,
          enabled: curr.enabled,
        });
      }

      return acc;
    },
    []
  );

  const getRaterProducts = (product: string) => {
    const productMap: { [key: string]: string } = {
      Home: 'Homeowners',
      Auto: 'Private Passenger Auto',
    };
    const productArray = product.split(',').map((p) => productMap[p.trim()]);

    return (
      <>
        {productArray.map((product, index) => (
          <div key={index}>{product}</div>
        ))}
      </>
    );
  };

  return (
    <Dialog open={open}>
      <div
        className="flex justify-between items-center gap-2.5"
        data-testid={'send-all-rater-dialog-title'}
      >
        <h6 className="leading-[26.6px] font-semibold text-light-text-contrast dark:text-dark-text-contrast">
          Ready to send?
        </h6>
        <Button onClick={toggleRaterDialog} variant={'ghost'} size={'icon'}>
          <span className="material-symbols-outlined text-[20px] text-light-icons-secondary dark:text-dark-icons-secondary">
            close
          </span>
        </Button>
      </div>
      <div className="mt-3 gap-3 text-light-text-contrast dark:text-dark-text-contrast text-sm">
        <table className="w-full text-left table-auto mb-4">
          <thead>
            <tr>
              <td className="p-4 pt-3 pb-3 border-b border-t border-[#cad0db]">
                <strong>Rater(s)</strong>
              </td>
              <td className="p-4 pt-3 pb-3 border-b border-t border-[#cad0db]">
                <strong>Product(s)</strong>
              </td>
            </tr>
          </thead>
          <tbody>
            {consolidatedRaters
              .filter((rater) => rater.enabled === true)
              .sort((a, b) => a.rater.localeCompare(b.rater))
              .map((raterProduct) => (
                <tr key={raterProduct.rater}>
                  <td className="p-4 border-b border-[#cad0db]">
                    <Checkbox
                      label={`${
                        raterProduct.rater === 'EZLynx' ? 'EZLynx' : 'QuoteRUSH'
                      }`}
                      checked={
                        raterProduct.rater === 'EZLynx'
                          ? selectedRaters.EZLynx
                          : selectedRaters.QuoteRush && !successQRQuoteGroup
                      }
                      disabled={
                        raterProduct.rater === 'EZLynx'
                          ? false
                          : !!successQRQuoteGroup
                      }
                      onChange={() =>
                        toggleSelectRater(
                          raterProduct.rater === 'EZLynx'
                            ? RatersEnum.enum.EZLynx
                            : RatersEnum.enum.QuoteRush
                        )
                      }
                    />
                  </td>
                  <td className="p-4 border-b border-[#cad0db]">
                    {getRaterProducts(raterProduct.product)}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {hasQuoteRush && successQRQuoteGroup ? (
          <p data-testid={'sent-to-QR-message'}>
            Data has been{' '}
            <span className="font-semibold">sent to QuoteRUSH already</span>.{' '}
            Please make further adjustments directly in QuoteRUSH.
          </p>
        ) : (
          <div
            className="flex flex-col gap-3"
            data-testid={'send-all-rater-dialog-message'}
          >
            {hasQuoteRush && (
              <table className="w-full text-left table-auto">
                <tbody>
                  <tr>
                    <td className="align-top">
                      <span className="material-symbols-outlined text-sm pr-2">
                        info
                      </span>
                    </td>
                    <td>
                      <p>
                        Data{' '}
                        <span className="font-semibold">
                          can only be sent to QuoteRUSH once
                        </span>
                        . You can make further adjustments directly in
                        QuoteRUSH.
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        )}
      </div>
      <div className="flex justify-end gap-2.5 pt-2">
        <Button
          data-testid={'send-to-all-raters-button'}
          onClick={onClickSend}
          variant={'link'}
          className="py-1.5 px-3"
          disabled={noneSelected}
        >
          <span>Send</span>
        </Button>
        <Button onClick={toggleRaterDialog} variant={'outline'} size={'sm'}>
          Cancel
        </Button>
      </div>
    </Dialog>
  );
};

export { SendToRaterDialog };
