import styles from './header.module.css';
import '../../../../theme.module.css';

export default function Header() {
  return (
    <div className={styles.header}>
      <img
        src="/img/fusion_yellow_icon.svg"
        alt="Brightway Insurance"
        width="48"
        height="48"
      />
      <h4 className={styles.fusionLabel}>FUSION&nbsp;</h4>
      <h4 className={styles.appLabel}>documents</h4>
    </div>
  );
}
