import { useEffect, useState } from 'react';
import Button from '../../../../components/shared/button';
import Dialog from '../../../../components/shared/dialog';
import { sendEmail } from '../../services/documentService';
import { Document } from '../../models/document';
import Checkbox from '../../../../components/shared/checkbox';
import TextField from '../../../../components/shared/textField';
import Icon from '../../../../components/Icon';
import { Customer } from '../../models/customer';
import { ListOption, Select } from '../../../../components/shared/select';
import { useAuthenticatedFetch } from '../../../../components/common/useAuthenticatedFetch';
import { Contact } from '../../models/contact';
import styles from './email.module.css';
import '../../../../theme.module.css';

export default function Email({
  customer,
  selectedDocumentList,
  associatedContactList,
  open,
  closeDialog,
  showAlertToast,
  deselectAll,
}: {
  customer: Customer;
  selectedDocumentList: Document[];
  associatedContactList: Contact[];
  open: boolean;
  closeDialog: any;
  showAlertToast: any;
  deselectAll: () => void;
}) {
  const [selectedOption, setSelectedOption] = useState<string>('customer');
  const [emailOptionList, setEmailOptionList] = useState<ListOption[]>([]);
  const [selectedEmail, setSelectedEmail] = useState<string>(
    associatedContactList && associatedContactList.length === 1
      ? associatedContactList[0].emailAddress
      : ''
  );
  const [altEmailAddress, setAltEmailAddress] = useState<string>('');
  const authedFetch = useAuthenticatedFetch();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const mappedValues = associatedContactList.map(
      (contact: Contact) =>
        ({
          label: contact.emailAddress,
        } as ListOption)
    );
    if (associatedContactList.length === 1) {
      mappedValues[0].selected = true;
      setSelectedEmail(mappedValues[0].label);
    }
    setEmailOptionList(mappedValues);
  }, [associatedContactList]);

  const handleEmailSelect = (clickedEmail: ListOption) => {
    setSelectedEmail(clickedEmail.label);

    const emailOptionListCopy = [...emailOptionList];
    emailOptionListCopy.forEach((email: ListOption) => {
      if (email.label === clickedEmail.label) {
        email.selected = true;
      } else {
        email.selected = false;
      }
    });
    setEmailOptionList(emailOptionListCopy);
  };

  const handleSendEmail = () => {
    if (!selectedDocumentList.length || !(selectedEmail || altEmailAddress)) {
      return;
    }

    setLoading(true);
    sendEmail(
      selectedOption === 'customer' ? customer.firstName : '',
      customer.customerId,
      selectedOption === 'customer' ? selectedEmail : altEmailAddress,
      selectedDocumentList.map((document: Document) => document.id),
      authedFetch
    )
      .then((result: any) => {
        showAlertToast(
          'success',
          `File${selectedDocumentList.length > 1 ? 's' : ''} sent to "${
            selectedOption === 'customer' ? selectedEmail : altEmailAddress
          }"`
        );
        resetThenClose();
        deselectAll();
      })
      .catch((error: any) => {
        showAlertToast(
          'warning',
          `Error sending file${selectedDocumentList.length > 1 ? 's' : ''}`
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const resetThenClose = () => {
    setSelectedOption('customer');
    setSelectedEmail(
      associatedContactList && associatedContactList.length === 1
        ? associatedContactList[0].emailAddress
        : ''
    );
    setAltEmailAddress('');

    const emailOptionListCopy = [...emailOptionList];
    emailOptionListCopy.forEach(
      (email: ListOption) => (email.selected = false)
    );
    setEmailOptionList(emailOptionListCopy);

    closeDialog();
  };

  return (
    <Dialog open={open} theme={'crm'}>
      <div className={styles.header}>
        <h5>Select Recipient</h5>
        <div className={styles.closeIcon} onClick={resetThenClose}>
          <Icon type={'dismiss'} color={'black'} size={20} />
        </div>
      </div>
      <div className={styles.body}>
        <div>
          <Checkbox
            label={`Customer's authorized contacts`}
            type={'radio'}
            theme={'crm'}
            checked={selectedOption === 'customer'}
            onChange={() => setSelectedOption('customer')}
          />
          <div className={styles.inputContainer}>
            <Select
              label={''}
              theme={'crm'}
              options={emailOptionList}
              placeholder={
                associatedContactList && associatedContactList.length
                  ? 'Select or enter an email address...'
                  : `Enter customer's email address...`
              }
              updateValue={handleEmailSelect}
              combo={true}
              disabled={selectedOption !== 'customer'}
            />
          </div>
        </div>
        <div>
          <Checkbox
            label={`Someone else`}
            type={'radio'}
            theme={'crm'}
            checked={selectedOption === 'other'}
            onChange={() => setSelectedOption('other')}
          />
          <div className={styles.inputContainer}>
            <TextField
              placeholder={`Enter email address...`}
              value={altEmailAddress}
              theme={'crm'}
              onChange={(value: string) => setAltEmailAddress(value)}
              disabled={selectedOption !== 'other'}
            />
          </div>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button
          callback={handleSendEmail}
          type={'primary'}
          theme={'crm'}
          disabled={!(selectedEmail || altEmailAddress)}
          loading={loading}
        >
          Send
        </Button>
        <Button callback={resetThenClose} type={'secondary'} theme={'crm'}>
          Cancel
        </Button>
      </div>
    </Dialog>
  );
}
