import Button from '../../../../components/shared/button';
import Dialog from '../../../../components/shared/dialog';
import { Contact } from '../../models/contact';
import { ListOption, Select } from '../../../../components/shared/select';
import Icon from '../../../../components/Icon';
import styles from './EmailComponent.module.css';

interface Props {
  resetThenClose(): void;
  handleEmailSelect(clickedEmail: ListOption): void;
  handleSendEmail(): void;
  emailOptionList: ListOption[];
  associatedContactList: Contact[];
  selectedEmail: string;
  altEmailAddress: string;
  loading: boolean;
  open: boolean;
}

const EmailComponent: React.FC<Props> = ({
  resetThenClose,
  handleEmailSelect,
  handleSendEmail,
  emailOptionList,
  associatedContactList,
  selectedEmail,
  altEmailAddress,
  loading,
  open,
}) => {
  return (
    <Dialog open={open} theme={'crm'}>
      <div className={styles.header}>
        <h5>Select recipient</h5>
        <div className={styles.closeIcon} onClick={resetThenClose}>
          <Icon type={'dismiss'} color={'black'} size={20} />
        </div>
      </div>
      <div className={styles.body}>
        <Select
          label={''}
          theme={'crm'}
          options={emailOptionList}
          placeholder={
            associatedContactList && associatedContactList.length
              ? 'Select or enter an email address...'
              : `Enter customer's email address...`
          }
          updateValue={handleEmailSelect}
          combo={true}
        />
      </div>
      <div className={styles.buttonContainer}>
        <Button
          callback={handleSendEmail}
          type={'primary'}
          theme={'crm'}
          disabled={!(selectedEmail || altEmailAddress)}
          loading={loading}
        >
          Send
        </Button>
        <Button callback={resetThenClose} type={'secondary'} theme={'crm'}>
          Cancel
        </Button>
      </div>
    </Dialog>
  );
};

export default EmailComponent;
