import { useEffect, useRef, useState } from 'react';
import Checkbox from './checkbox';
import TextField from './textField';
import styles from './select.module.css';
import '../../theme.module.css';
import Icon from '../Icon';

/**
 * Displays a dropdown componet.
 * 
 * @param {Object} config - The configuration object for this component.
 * @param {string} config.label - The text to display above the input.
 * @param {Array} config.options - The list of options to display in the dropdown.
 * @param {boolean} [config.required] - Flag indicating whether the field is required. 
 * @param {boolean} [config.multiple] - Flag indicating whether multi-select is allowed. 
 * @param {boolean} [config.combo] - Flag indicating whether to provide a text input field.
 * @param {boolean} [config.disabled] - Flag indicating whether the field is disabled.  
 * @param {string} [config.placeholder] - Placeholder text to be displayed in the field.
 * @param {function} config.updateValue - Callback function used to update the value when the dropdown changes.
 * 
 * @example 
 * <Select 
        label={'Policy #'} 
        options={policyList} 
        placeholder={'Select a policy...'} 
        updateValue={setPolicy}
   />
 */
export function Select({
  label,
  options,
  required,
  multiple,
  combo,
  disabled,
  placeholder,
  updateValue,
  theme = 'bw',
}: {
  label: string;
  options: ListOption[];
  required?: boolean;
  multiple?: boolean;
  combo?: boolean;
  disabled?: boolean;
  placeholder?: string;
  updateValue: any;
  theme?: 'bw' | 'crm';
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [headerTitle, setHeaderTitle] = useState<string>(
    placeholder && !combo ? placeholder : ''
  );
  const [inputValue, setInputValue] = useState<string>('');
  const [groupedOptions, setGroupedOptions] = useState<GroupedOptionList>(
    {} as GroupedOptionList
  );

  const [dropdownPosition, setDropdownPosition] = useState<number>(0);
  const dropdownRef: { current: any } = useRef(null);

  useEffect(() => {
    setHeader();
    groupOptions();
  }, [options]);

  // this sets the height of the select dropdown
  // to make sure it doesn't overflow the window
  useEffect(() => {
    function updateDropdownPosition() {
      if (dropdownRef.current) {
        const rect = dropdownRef.current.getBoundingClientRect();
        setDropdownPosition(window.innerHeight - rect.bottom - 20);
      }
    }

    window.addEventListener('resize', updateDropdownPosition);
    updateDropdownPosition();
    return () => window.removeEventListener('resize', updateDropdownPosition);
  }, []);

  const toggleList = (event: any) => {
    event.stopPropagation();
    if (disabled) {
      return;
    }
    setIsOpen(!isOpen);
  };

  const selectItem = (event: any, option: ListOption) => {
    event.stopPropagation();
    if (option.disabled) {
      return;
    }
    updateValue(option);
    if (!multiple) {
      setIsOpen(false);
    }
  };

  const handleInputChange = (value: string) => {
    const inputAsOption: ListOption = {
      label: value,
      value: value,
    };
    updateValue(inputAsOption);
    setInputValue(value);
  };

  const setHeader = () => {
    const titleList: string[] = [];
    options.forEach((option: ListOption) => {
      if (option.selected) {
        titleList.push(option.label);
      }
    });
    if (titleList.length) {
      setHeaderTitle(titleList.sort().join(', '));
    } else if (combo) {
      setHeaderTitle(inputValue || '');
    } else {
      setHeaderTitle(placeholder || '');
    }
  };

  const groupOptions = () => {
    let tempGroupedOptions: GroupedOptionList = {};
    // create an objected grouped by each header value:
    // {
    //    2024: [{...policy object B...}, {...policy object C...}, {...policy object A...}],
    //    2023: [{...policy object Z...}, {...policy object X...}]
    // }
    options.forEach((option: ListOption) => {
      if (option.groupByValue) {
        if (tempGroupedOptions[option.groupByValue]) {
          tempGroupedOptions[option.groupByValue].push(option);
        } else {
          tempGroupedOptions[option.groupByValue] = [];
          tempGroupedOptions[option.groupByValue].push(option);
        }
      } else {
        if (tempGroupedOptions.all) {
          tempGroupedOptions.all.push(option);
        } else {
          tempGroupedOptions = {
            all: [],
          };
          tempGroupedOptions.all.push(option);
        }
      }
    });

    // Sort the list for each section
    // {
    //    2024: [{...policy object A...}, {...policy object B...}, {...policy object C...}],
    //    2023: [{...policy object X...}, {...policy object Z...}]
    // }
    Object.keys(tempGroupedOptions).forEach((key: string) => {
      tempGroupedOptions[key].sort((a: ListOption, b: ListOption) => {
        return a.label < b.label ? -1 : 1;
      });
    });

    setGroupedOptions(tempGroupedOptions);
  };

  const getListItems = (list: ListOption[]) => {
    return list.map((option: ListOption) => (
      <div
        className={[
          styles.optionContainer,
          option.disabled ? styles.disabled : undefined,
        ].join(' ')}
        key={`list-element-${option.label}`}
        onClick={(event: any) => selectItem(event, option)}
      >
        <div className={styles.option}>
          {multiple ? (
            <Checkbox
              label={option.label}
              theme={theme}
              checked={!!option.selected}
              disabled={option.disabled}
            />
          ) : (
            <>{option.label}</>
          )}
        </div>
      </div>
    ));
  };

  return (
    <label className={styles[theme]}>
      <span className={styles.label}>
        {label}
        {required && <span className={styles.required}>*</span>}
      </span>
      <div
        ref={dropdownRef}
        className={[
          styles.select,
          placeholder && placeholder === headerTitle
            ? styles.placeholder
            : undefined,
          disabled ? styles.disabled : undefined,
          isOpen ? styles.open : undefined,
        ].join(' ')}
        onClick={(event: any) => (combo ? null : toggleList(event))}
      >
        {combo ? (
          <input
            type="text"
            className={styles.input}
            onChange={(event) => handleInputChange(event.target.value)}
            value={headerTitle}
            placeholder={placeholder}
            disabled={disabled}
          />
        ) : (
          <div className={styles.selectedValue}>{headerTitle}</div>
        )}
        {
          <div
            className={isOpen ? styles.flip : undefined}
            onClick={(event: any) => (combo ? toggleList(event) : null)}
          >
            <Icon
              type={'keyboard_arrow_down'}
              color={'text-secondary'}
              size={24}
            />
          </div>
        }
      </div>
      {isOpen && (
        <>
          <div
            className={styles.overlay}
            onClick={() => setIsOpen(false)}
          ></div>
          <div className={styles.dropdownContainer}>
            <div
              style={{ maxHeight: dropdownPosition }}
              className={styles.dropdown}
            >
              {Object.keys(groupedOptions)
                .sort((a, b) => (a < b ? 1 : -1))
                .map((key: string, index: number) => {
                  return (
                    <>
                      {key !== 'all' && (
                        <div
                          className={styles.groupHeader}
                          key={`group-header-${key}`}
                        >
                          {key}
                        </div>
                      )}
                      {getListItems(groupedOptions[key] as ListOption[])}
                    </>
                  );
                })}
              {Object.keys(groupedOptions).length === 0 && (
                <div className={styles.noOptionsContainer}>
                  <div className={styles.noOptions}>No options</div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </label>
  );
}

export interface ListOption {
  label: string;
  value?: any;
  groupByValue?: any;
  selected?: boolean;
  disabled?: boolean;
}

interface GroupedOptionList {
  [id: string]: ListOption[];
}
