'use client';

import { useEffect, useState } from 'react';
import { Document } from '../../models/document';
import Checkbox from '../../../../components/shared/checkbox';
import Icon from '../../../../components/Icon';
import {
  Column,
  Table,
  TableCell,
  TableHead,
  TableHeadCell,
  TableText,
  TableTextInterface,
} from '../../../../components/shared/table';
import Button from '../../../../components/shared/button';
import {
  getDocumentBlob,
  getDocumentLink,
} from '../../services/documentService';
import { DocumentUrl } from '../../models/documentUrl';
import { useAuthenticatedFetch } from '../../../../components/common/useAuthenticatedFetch';
import Tooltip from '../../../../components/shared/tooltip';
import { Policy } from '../../models/policy';
import styles from './documentTable.module.css';
import '../../../../theme.module.css';

export default function DocumentTable({
  documents,
  setSelectedDocuments,
  tableText,
  showAlertToast,
}: {
  documents: Document[] | undefined;
  setSelectedDocuments: any;
  tableText: TableTextInterface;
  showAlertToast: (type: string, text: string) => void;
}) {
  const botUser = 'Bot User';
  const timeFormat = new Intl.DateTimeFormat('en-US', {
    timeZone: 'UTC',
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
  });
  const authedFetch = useAuthenticatedFetch();
  const columns: Column[] = [
    {
      label: '',
      accessor: '',
      headerClasses: `${styles.textCenter}`,
      headerContent: () => (
        <Checkbox
          checked={selectAll}
          onChange={() => toggleSelectAll()}
          theme={'crm'}
        />
      ),
      cellClasses: `${styles.textCenter}`,
      cellContent: (row: Document, item: string) => (
        <Checkbox
          checked={!!row.selected || false}
          onChange={() => toggleSelection(row.id, !row.selected)}
          theme={'crm'}
        />
      ),
      sortable: false,
    },
    {
      label: 'Name',
      accessor: 'fileOriginalName',
      cellClasses: `${styles.noWrap}`,
      cellContent: (row: Document, item: string) => (
        <Button type={'link'} callback={() => viewDocument(row)} theme={'crm'}>
          {item}
        </Button>
      ),
      sortable: true,
    },
    {
      label: 'Policy #',
      accessor: 'policyNumber',
      cellClasses: `${styles.noWrap}`,
      cellContent: (row: Document, item: string) => {
        const policyCount =
          row.links && row.links.policy && row.links.policy.length
            ? row.links.policy.length
            : null;
        if (row.loading) {
          return <span className={styles.loadingText}>Loading...</span>;
        } else if (policyCount === 1) {
          return row.links?.policy[0].policyNumber;
        } else if (policyCount && policyCount > 1) {
          return (
            <Tooltip
              message={getPolicyHover(row.links?.policy || [])}
              position={'auto'}
              theme={'crm'}
            >
              <span className={styles.multiplePolicies}>
                Multiple ({policyCount})
              </span>
            </Tooltip>
          );
        } else {
          return '-';
        }
      },
      sortable: true,
    },
    {
      label: 'Term',
      accessor: 'term',
      cellClasses: `${styles.noWrap}`,
      cellContent: (row: Document, item: string) => {
        const policyCount =
          row.links && row.links.policy && row.links.policy.length
            ? row.links.policy.length
            : null;
        if (row.loading) {
          return;
        } else if (
          policyCount === 1 &&
          row.links?.policy[0].effectiveDate &&
          row.links?.policy[0].expirationDate
        ) {
          return formatTerm(
            row.links?.policy[0].effectiveDate,
            row.links?.policy[0].expirationDate
          );
        } else {
          return '-';
        }
      },
      sortable: true,
      sortFieldAccessor: 'effectiveDate',
    },
    {
      label: 'Category',
      accessor: 'documentType',
      cellClasses: `${styles.noWrap}`,
      sortable: true,
    },
    {
      label: 'Added to Fusion',
      accessor: 'createdAt',
      cellClasses: `${styles.noWrap}`,
      cellContent: (row: Document, item: string) => {
        return timeFormat.format(new Date(item));
      },
      sortable: true,
      sortByOrder: 'desc',
    },
    {
      label: 'Added by',
      accessor: 'createdBy',
      cellClasses: `${styles.noWrap}`,
      cellContent: (row: Document, item: string) =>
        row.createdBy?.name === botUser ? (
          <div title={botUser}>
            <Icon type={'sparkle'} color={'black'} viewbox={'0 -4 24 24'} />
          </div>
        ) : (
          row.createdBy?.name
        ),
      sortable: true,
      sortFunction: (
        a: any,
        b: any,
        sortField: string,
        order,
        defaultSort: (a: any, b: any) => number
      ) => {
        if (a[sortField] === botUser) {
          return order === 'asc' ? -1 : 1;
        } else if (b[sortField] === botUser) {
          return order === 'asc' ? 1 : -1;
        } else {
          return defaultSort(a, b);
        }
      },
    },
    {
      label: 'Note',
      accessor: 'notes',
      cellContent: (row: Document, item: string) => <>{item ? item : '-'}</>,
      sortable: true,
    },
  ];
  const [documentList, setDocumentList] = useState<Document[] | undefined>();
  const [selectAll, setSelectAll] = useState<boolean>(false);

  const toggleSelection = (documentId: string, isChecked = false) => {
    setDocumentList((prevState: any) => {
      const documentListCopy = [...prevState];
      documentListCopy.forEach((document: Document) => {
        if (document.id === documentId) {
          document.selected = isChecked;
        }
      });
      return documentListCopy;
    });
  };

  const toggleSelectAll = () => {
    const newSelectAllState = !selectAll;
    setDocumentList((prevState: any) => {
      const documentListCopy = [...prevState];
      documentListCopy.forEach((document: Document) => {
        document.selected = newSelectAllState;
      });
      return documentListCopy;
    });

    setSelectAll(newSelectAllState);
  };

  const viewDocument = async (document: Document) => {
    const openLink = (url: string, filename: string) => {
      const link = window.document.createElement('a');
      link.href = url;
      link.download = filename;
      link.target = '_blank';
      link.click();

      window.URL.revokeObjectURL(url);
      link.remove();
    };

    try {
      if (document.fileUrl) {
        //get link to file in blob storage
        const documentLink: DocumentUrl = await getDocumentLink(
          document.id,
          authedFetch
        );
        if (documentLink) {
          openLink(documentLink.url, document.fileOriginalName);
        }
      } else {
        //get file from ams
        const blob = await getDocumentBlob(document.id, authedFetch);
        const url = window.URL.createObjectURL(blob);
        openLink(url, document.fileOriginalName);
      }
    } catch (err) {
      showAlertToast('warning', 'Error viewing document.');
    }
  };

  const formatTerm = (
    effectiveDate: string,
    expirationDate: string
  ): string => {
    if (!effectiveDate || !expirationDate) {
      return '-';
    }

    return (
      timeFormat.format(new Date(effectiveDate)) +
      ' - ' +
      timeFormat.format(new Date(expirationDate))
    );
  };

  const getPolicyHover = (policyList: Policy[]): string => {
    const hoverText = `Associated policies:
${policyList
  .map(
    (policy: Policy) =>
      ' •  ' +
      policy.policyNumber +
      ' (' +
      formatTerm(policy.effectiveDate, policy.expirationDate) +
      ')'
  )
  .join('\n')}`;

    return hoverText;
  };

  useEffect(() => {
    if (documents) {
      setDocumentList(documents);
    }
  }, [documents]);

  useEffect(() => {
    if (documentList) {
      setSelectedDocuments(
        documentList.filter((document: Document) => !!document.selected)
      );
    }
  }, [documentList]);

  return (
    <div className={styles.tableContainer}>
      <Table
        classes={styles.table}
        theme={'crm'}
        columns={columns}
        data={documentList}
        tableText={tableText}
      />
    </div>
  );
}
