import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { policyIsSelected } from './helpers';
import { FetchedDataState, PolicyKeySet } from './types';
import { Form } from '../../models/form';
import { Customer } from '../../models/customer';
import { Contact } from '../../models/contact';

const FormContext = createContext<IFormContext | undefined>(undefined);

export const useFormContext = (): IFormContext => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error('useFormContext must be used within a Form Provider');
  }
  return context;
};

export const FormProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [selectedPolicyKeys, setSelectedPolicyKeys] = useState<PolicyKeySet[]>(
    []
  );
  const [selectedFormListKeys, setSelectedFormListKeys] = useState<string[]>(
    []
  );
  const [fetchedData, setFetchedData] = useState<FetchedDataState>({
    state: 'initialized',
  });
  const [forms, setForms] = useState<Form[]>([]);

  useEffect(() => {
    if (fetchedData.state === 'complete') {
      setForms(fetchedData.content.formList);
    }
  }, [fetchedData]);

  const [customer, setCustomer] = useState<Customer | undefined>(undefined);
  const [associatedContactList, setAssociatedContactList] = useState<Contact[]>(
    []
  );

  useEffect(() => {
    if (fetchedData.state === 'complete') {
      setCustomer(fetchedData.content.customer);
      setAssociatedContactList(fetchedData.content.associatedContactList);
    } else {
      setCustomer(undefined);
    }
  }, [fetchedData]);

  const togglePolicy = useCallback((policyKey: string, formKey: string) => {
    setSelectedPolicyKeys((currentSelectedPolicyListKeys) => {
      if (policyIsSelected(policyKey, formKey, currentSelectedPolicyListKeys)) {
        return currentSelectedPolicyListKeys.filter(
          (selectedPolicyKeySet) =>
            selectedPolicyKeySet.formKey !== formKey ||
            selectedPolicyKeySet.policyKey !== policyKey
        );
      } else {
        return currentSelectedPolicyListKeys.concat({
          formKey,
          policyKey,
        });
      }
    });
  }, []);

  const toggleFormKey = useCallback(
    (formKey: string) => {
      setSelectedFormListKeys((currentSelectedFormListKeys) => {
        if (currentSelectedFormListKeys.includes(formKey)) {
          return currentSelectedFormListKeys.filter((key) => key !== formKey);
        } else {
          return [...currentSelectedFormListKeys, formKey];
        }
      });
    },
    [setSelectedFormListKeys]
  );

  const deselectAll = useCallback(() => {
    setSelectedFormListKeys([]);
  }, [setSelectedFormListKeys]);

  const toggleCategory = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, formKey: string) => {
      event.stopPropagation();
      const foundForm = forms.find((formItem) => formItem.key === formKey);
      const policies = foundForm?.policies ?? [];
      for (const policy of policies) {
        togglePolicy(policy.amsPolicyId, formKey);
      }
    },
    [forms, togglePolicy]
  );

  return (
    <FormContext.Provider
      value={{
        selectedPolicyKeys,
        selectedFormListKeys,
        togglePolicy,
        toggleFormKey,
        deselectAll,
        fetchedData,
        setFetchedData,
        forms,
        customer,
        associatedContactList,
        toggleCategory,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

interface IFormContext {
  selectedPolicyKeys: PolicyKeySet[];
  selectedFormListKeys: string[];
  togglePolicy: (policyKey: string, formKey: string) => void;
  toggleFormKey: (formKey: string) => void;
  deselectAll: () => void;
  fetchedData: FetchedDataState;
  setFetchedData: React.Dispatch<React.SetStateAction<FetchedDataState>>;
  forms: Form[];
  customer: Customer | undefined;
  associatedContactList: Contact[];
  toggleCategory: (
    event: React.MouseEvent<HTMLButtonElement>,
    formKey: string
  ) => void;
}
