import { Form } from '../../models/form';
import { Policy } from '../../models/policy';
import { FetchedDataState, PolicyKeySet } from './types';

const formIncludesPolicy = (form: Form, policy: Policy) => {
  return form.metadata?.linesOfBusiness?.includes(policy.lineOfBusiness);
};

// TODO: make this work without mutating
export const combineFormsAndPolicies = (
  forms: Form[],
  policies: Policy[]
): Form[] => {
  for (const form of forms) {
    form.policies = [];
    for (const policy of policies) {
      if (formIncludesPolicy(form, policy)) {
        form.policies.push(policy);
      }
    }
  }
  return forms.filter((form) => form.policies && form.policies.length > 0);
};

//For customer level forms, metadata IS NOT null, and lineofBusiness should be an empty array
export const getCustomerLevelForms = (forms: Form[]): Form[] => {
  return forms.filter(
    (form) =>
      form.metadata?.linesOfBusiness &&
      form.metadata.linesOfBusiness.length === 0
  );
};

export const policyIsSelected = (
  policyID: string,
  formKey: string,
  currentSelectedList: PolicyKeySet[]
): boolean => {
  const formIsSelected = currentSelectedList.some((selectedKeySet) => {
    return (
      policyID === selectedKeySet.policyKey &&
      selectedKeySet.formKey === formKey
    );
  });
  return formIsSelected;
};

export const getUsernameFromFetchedData = (
  fetchedData: FetchedDataState
): string | undefined => {
  if (fetchedData.state === 'complete') {
    return fetchedData.content.username;
  } else {
    return undefined;
  }
};
