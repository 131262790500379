import { useEffect, useState } from 'react';
import Button from '../../../../components/shared/button';
import Dialog from '../../../../components/shared/dialog';
import { Document } from '../../models/document';
import { saveName } from '../../services/documentService';
import TextField from '../../../../components/shared/textField';
import Icon from '../../../../components/Icon';
import { useAuthenticatedFetch } from '../../../../components/common/useAuthenticatedFetch';
import styles from './rename.module.css';
import '../../../../theme.module.css';

export default function Rename({
  selectedDocument,
  open,
  closeDialog,
  showAlertToast,
  deselectAll,
  refreshData,
  updateField,
}: {
  selectedDocument: Document;
  open: boolean;
  closeDialog: any;
  showAlertToast: any;
  deselectAll: () => void;
  refreshData: () => void;
  updateField: (
    documentId: string,
    field: 'name' | 'policy' | 'category' | 'note',
    value: any
  ) => void;
}) {
  const [name, setName] = useState<string>('');
  const authedFetch = useAuthenticatedFetch();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (open) {
      setName(selectedDocument.fileOriginalName);
    }
  }, [open]);

  const handleSave = () => {
    setLoading(true);
    saveName(selectedDocument, name, authedFetch)
      .then((result: Document) => {
        closeDialog();
        showAlertToast('success', `File name changed to: "${name}"`);
        deselectAll();
        updateField(selectedDocument.id, 'name', name);
        refreshData();
      })
      .catch(() => {
        showAlertToast('warning', `Error renaming file`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Dialog open={open} theme={'crm'}>
      <div className={styles.header}>
        <h5>Rename</h5>
        <div className={styles.closeIcon} onClick={closeDialog}>
          <Icon type={'dismiss'} color={'black'} size={20} />
        </div>
      </div>
      <div className={styles.body}>
        <TextField
          label={'Document name'}
          theme={'crm'}
          value={name}
          onChange={(value: string) => setName(value)}
        />
      </div>
      <div className={styles.buttonContainer}>
        <Button
          callback={handleSave}
          type={'primary'}
          theme={'crm'}
          disabled={!name}
          loading={loading}
        >
          Save
        </Button>
        <Button callback={closeDialog} type={'secondary'} theme={'crm'}>
          Cancel
        </Button>
      </div>
    </Dialog>
  );
}
