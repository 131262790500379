/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
import { InsuranceProduct, Raters } from '@bwinsurance/meta-rater-types';

export enum INSURANCE_PRODUCT_TO_CRM_PRODUCT {
  Auto = 'Private Passenger Auto',
  Boat = 'Watercraft (small boat)',
  Business = 'General Liability',
  Condo = 'Homeowners',
  Flood = 'Flood',
  Home = 'Homeowners',
  Homeowners = 'Homeowners',
  'Home Warranty' = 'Home Warranty',
  Life = 'Life',
  Motorcycle = 'Motorcycle',
  'Personal Articles' = 'Inland Marine (P)',
  'Personal Passenger Auto' = 'Personal Passenger Auto',
  Pets = 'Pet Health Insurance',
  Renters = 'Homeowners',
  RV = 'Personal Travel Trailer',
  Umbrella = 'Umbrella (P)',
}

export type RaterProductEnabled = {
  rater: Raters;
  product: InsuranceProduct;
  enabled: boolean;
};

export type RaterSelectedMap = Record<Raters, boolean>;
