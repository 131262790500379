import { useEffect, useRef, useState } from 'react';
import Button from '../../../../components/shared/button';
import Dialog from '../../../../components/shared/dialog';
import TextField from '../../../../components/shared/textField';
import Icon from '../../../../components/Icon';
import { ListOption, Select } from '../../../../components/shared/select';
import { Policy } from '../../models/policy';
import SectionDivider from '../../../../components/shared/sectionDivider';
import { addFileToFusion } from '../../services/documentService';
import { UploadData } from '../../models/uploadData';
import { IPublicClientApplication } from '@azure/msal-browser';
import { useAuthenticatedFetch } from '../../../../components/common/useAuthenticatedFetch';
import styles from './upload.module.css';
import '../../../../theme.module.css';

export default function Upload({
  open,
  closeDialog,
  files = [],
  categoryList,
  accountId,
  policyList,
  showAlertToast,
  refreshData,
  pca,
  userName,
}: {
  open: boolean;
  closeDialog: any;
  files?: FileList[];
  categoryList: string[];
  accountId: string;
  policyList: Policy[];
  showAlertToast: (type: string, message: string) => void;
  refreshData: () => void;
  pca: IPublicClientApplication;
  userName: string;
}) {
  const fileInputRef: { current: any } = useRef<HTMLInputElement>(null);
  const [fileDisplayName, setFileDisplayName] = useState<string>('');

  const [category, setCategory] = useState<string>('');
  const [categoryOptionList, setCategoryOptionList] = useState<ListOption[]>(
    []
  );

  const [notes, setNotes] = useState<string>('');

  const [selectedPolicies, setSelectedPolicies] = useState<string[]>([]);
  const [policyOptionList, setPolicyOptionList] = useState<ListOption[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const authedFetch = useAuthenticatedFetch();

  useEffect(() => {
    const mappedValues = policyList.map(
      (policy: Policy) =>
        ({
          label: `${policy.carrier.name} (${policy.policyNumber})`,
          value: policy.policyId,
          groupByValue: new Date(policy.effectiveDate).getFullYear(),
        } as ListOption)
    );
    setPolicyOptionList(mappedValues);
  }, [policyList]);

  useEffect(() => {
    const mappedValues = categoryList.map((category: string) => {
      return {
        label: category,
      } as ListOption;
    });
    setCategoryOptionList(mappedValues);
  }, [categoryList]);

  useEffect(() => {
    if (files && files.length && fileInputRef && fileInputRef.current) {
      fileInputRef.current.files = files;
      setFileDisplayName(fileInputRef.current.files[0].name);
    }
  }, [files]);

  const handlePolicySelect = (clickedPolicy: ListOption) => {
    const selectedPolicyIds: string[] = [];

    const policyOptionListCopy = [...policyOptionList];
    policyOptionListCopy.forEach((policy: ListOption) => {
      if (policy.value === clickedPolicy.value) {
        policy.selected = !policy.selected;
      }
      if (policy.selected) {
        selectedPolicyIds.push(policy.value);
      }
    });
    setPolicyOptionList(policyOptionListCopy);
    setSelectedPolicies(selectedPolicyIds);
  };

  const handleCategorySelect = (clickedCategory: ListOption) => {
    setCategory(clickedCategory.label);

    const categoryOptionListCopy = [...categoryOptionList];
    categoryOptionListCopy.forEach((category) => {
      if (category.label === clickedCategory.label) {
        category.selected = true;
      } else {
        category.selected = false;
      }
    });

    setCategoryOptionList(categoryOptionListCopy);
  };

  const save = () => {
    const user = pca.getActiveAccount();

    if (!user) {
      showAlertToast('warning', 'User not logged in.');
      return;
    }
    const data: UploadData = {
      file: fileInputRef.current.files[0],
      fileName: fileDisplayName,
      category,
      notes,
      policyList: selectedPolicies,
      accountId,
      userId: user.homeAccountId.split('.')[0],
      userName: userName,
    };

    setLoading(true);
    addFileToFusion(data, authedFetch)
      .then((result: string) => {
        resetThenClose();
        showAlertToast('success', `Added "${result}"`);
        refreshData();
      })
      .catch((error: string) => {
        showAlertToast('warning', 'Error adding file.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const resetThenClose = () => {
    setSelectedPolicies([]);
    setPolicyOptionList((prevState: ListOption[]) => {
      const optionListCopy = [...prevState];
      optionListCopy.forEach((option: ListOption) => {
        option.selected = false;
      });
      return optionListCopy;
    });

    setCategory('');
    setCategoryOptionList((prevState: ListOption[]) => {
      const optionListCopy = [...prevState];
      optionListCopy.forEach((option: ListOption) => {
        option.selected = false;
      });
      return optionListCopy;
    });

    setNotes('');
    setFileDisplayName('');

    closeDialog();
  };

  return (
    <Dialog open={open} theme={'crm'}>
      <div className={styles.header}>
        <h5>Upload a document</h5>
        <div className={styles.closeIcon} onClick={resetThenClose}>
          <Icon type={'dismiss'} color={'black'} size={20} />
        </div>
      </div>
      <SectionDivider title={'Properties'} theme={'crm'} />
      <div className={styles.inputContainer}>
        <label>
          <input
            type="file"
            ref={fileInputRef}
            className="hidden"
            accept="image/*, video/*, .doc, .docx, .pdf, .xml, .txt, .csv, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          />
          <TextField
            label={'Document name'}
            theme={'crm'}
            value={fileDisplayName}
            onChange={(value: string) => setFileDisplayName(value)}
            required={true}
          />
        </label>
        <Select
          label={'Category'}
          theme={'crm'}
          options={categoryOptionList}
          required={true}
          placeholder={'Select a category...'}
          updateValue={handleCategorySelect}
        />
        <TextField
          label={'Note'}
          theme={'crm'}
          value={notes}
          onChange={(value: string) => setNotes(value)}
          placeholder={'Add additional details here...'}
        />
      </div>

      <SectionDivider title={'Link to '} theme={'crm'} />
      <div className={styles.inputContainer}>
        <Select
          label={'Policy'}
          theme={'crm'}
          options={policyOptionList}
          placeholder={'Select an associated policy...'}
          updateValue={handlePolicySelect}
          multiple={true}
        />
      </div>
      <div className={styles.buttonContainer}>
        <Button
          callback={save}
          type={'primary'}
          theme={'crm'}
          disabled={!fileDisplayName || !category}
          loading={loading}
        >
          Add document
        </Button>
        <Button callback={resetThenClose} type={'secondary'} theme={'crm'}>
          Cancel
        </Button>
      </div>
    </Dialog>
  );
}
