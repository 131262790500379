import styles from './dialog.module.css';
import '../../theme.module.css';

/**
 * Displays a greyed out backdrop and dialog container.
 * 
 * @param {Object} config - The configuration object for this component.
 * @param {HTML} config.children - The markup to display in the dialog.
 * @param {boolean} config.open - Flag indicating whether the dialog is displayed.
 * @param {string} [config.theme] - Indicates which style theme to apply: bw or crm.
 * 
 * @example
 *  <Dialog open={open}>
        ...
        dialog contents
        ...
    </Dialog>
 */
export default function Dialog({
  children,
  open,
  theme = 'bw',
}: {
  children: any;
  open: boolean;
  theme?: 'bw' | 'crm';
}) {
  if (!open) {
    return;
  }
  return (
    <div className={[styles[theme], styles.overlay].join(' ')}>
      <div className={styles.dialog}>{children}</div>
    </div>
  );
}
